import React, { Component } from 'react'

// MUI
import Grid from '@mui/material/Grid';

class FacebookPost extends Component {
  render() {
    return (
      <div>

        <div className='facebookPostMain'>
            <a href={this.props.link} target="_blank" style={{textDecoration: 'none'}}>
                <Grid container spacing={0} style={{margin: 'auto'}}>
                    <Grid item xs={4} md={3} style={{marginTop: 'auto', marginBottom: 'auto', margin: 'auto'}}>
                    <img src={this.props.image} alt='Alex and Lera avatar' loading="lazy" className='AlexLeraCircle'/>
                    </Grid>
                    <Grid item xs={8} md={9} style={{marginTop: 'auto', marginBottom: 'auto', margin: 'auto'}}>
                    <h6 className='FacebookTopic'>{this.props.topic}</h6>
                    <p className='FacebookTitle'>{this.props.title}</p>
                    <p className='FacebookText'>{this.props.text}</p>
                    </Grid>
                </Grid>
                <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    marginRight: '24px',
                    fontWeight: '300',
                    fontSize: '14px',
                    marginBottom: '0px',
                    bottom: '24px',
                    color: '#6868AC',
                    textDecoration: 'underline'
                }}
                target="_blank"
                href={this.props.link}
                >
                    More..
                </div>
            </a>
        </div>

      </div>
    )
  }
}

export default FacebookPost;