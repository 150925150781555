import React, { Component } from "react";
import contactUsFillUpForm from "../styles/contactUsFillUpForm.css";
import smallHouse from "../images/smallHouse.png";

// MUI
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#F1EDE9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6868AC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F1EDE9",
      },
      "&:hover fieldset": {
        borderColor: "#F1EDE9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6868AC",
      },
    },
  },
})(TextField);

// %0A - new line
// %20 - space

const token = process.env.REACT_APP_TELEGRAM_TOKEN;
const chatId = process.env.REACT_APP_TELEGRAM_CHAT_ID;
// const textMessage = `Хай%20бэсти%0Ahi%20bestie`;

class ContactUsFillUpForm extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      comment: "",
      errors: {},
      helperText: "",
      heplerTextColor: "white",
      nameError: false,
      emailError: false,
      phoneNumberError: false,
    };
  }

  handleChange = (event) => {
    if (event.target.name === "comment") {
      this.setState({
        comment: event.target.value.slice(0, 400),
      });
    } else {
      this.setState({
        [event.target.name]: event.target.value,
      });
    }

    if (this.state.name.replaceAll(" ", "") !== "") {
      this.setState({
        nameError: false,
      });
    }
    if (this.state.email.replaceAll(" ", "") !== "") {
      this.setState({
        emailError: false,
      });
    }
    if (this.state.phoneNumber.replaceAll(" ", "") !== "") {
      this.setState({
        phoneNumberError: false,
      });
    }
  };

  sendInfoToTelegram = () => {
    let textMessage;
    textMessage = `%0A${this.state.name.replaceAll(
      " ",
      "%20"
    )}%0A%0A${this.state.email.replaceAll(
      " ",
      "%20"
    )}%0A%0A${this.state.phoneNumber.replaceAll(
      " ",
      "%20"
    )}%0A%0A${this.state.comment
      .replaceAll(" ", "%20")
      .replaceAll("\n", "%0A")}%0A`;

    fetch(
      `https://api.telegram.org/bot${token}/sendMessage?chat_id=${chatId}&text=${textMessage}`
    ).then((response) => response.json());
  };
  // "https://api.telegram.org/bot{token}/getUpdates"
  //https://api.telegram.org/bot<token>/sendMessage?chat_id=<group chat id >&text=<our text>

  handleSubmit = (event) => {
    event.preventDefault();
    const userData = {
      name: this.state.name,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      comment: this.state.comment,
    };

    // console.log(userData);
    // this.encodeInput();
    if (
      this.state.name.replaceAll(" ", "") === "" ||
      this.state.email.replaceAll(" ", "") === "" ||
      this.state.phoneNumber.replaceAll(" ", "") === ""
    ) {
      this.setState({
        helperText: "Please, fill up top 3 fields :)",
        heplerTextColor: "red",
      });

      if (this.state.name.replaceAll(" ", "") === "") {
        this.setState({
          nameError: true,
        });
      }
      if (this.state.email.replaceAll(" ", "") === "") {
        this.setState({
          emailError: true,
        });
      }
      if (this.state.phoneNumber.replaceAll(" ", "") === "") {
        this.setState({
          phoneNumberError: true,
        });
      }
      if (this.state.name.replaceAll(" ", "") !== "") {
        this.setState({
          nameError: false,
        });
      }
      if (this.state.email.replaceAll(" ", "") !== "") {
        this.setState({
          emailError: false,
        });
      }
      if (this.state.phoneNumber.replaceAll(" ", "") !== "") {
        this.setState({
          phoneNumberError: false,
        });
      }
    } else {
      this.sendInfoToTelegram();
      this.setState({
        helperText: "Success! Thank you, we will get back to you soon",
        heplerTextColor: "green",
        name: "",
        email: "",
        phoneNumber: "",
        comment: "",
        nameError: false,
        emailError: false,
        phoneNumberError: false,
      });
    }

    // this.props.loginUser(userData, this.props.history); //trigger API Request
  };

  render() {
    // let helperText = (
    //     <div>hi</div>
    // )

    return (
      <div className="ContactUsMainDiv">
        <Grid container spacing={0} style={{ display: "flex" }}>
          <Grid item xs={12} md={6}>
            <h2 className="contactUsHeader">CONTACT US</h2>
            <p className="contactUsMiniHeader">
              Leave your contact information and we will get back to you as soon
              as possible
            </p>
            <form noValidate onSubmit={this.handleSubmit}>
              <CssTextField
                error={this.state.nameError}
                id="name"
                name="name"
                type="name"
                label="Name"
                className="ContactTextField"
                variant="outlined"
                value={this.state.name}
                onChange={this.handleChange}
                // helperText={}
                // fullWidth
                margin="normal"
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px" }}
              />

              <CssTextField
                error={this.state.emailError}
                id="email"
                name="email"
                type="email"
                label="Email"
                className="ContactTextField"
                variant="outlined"
                value={this.state.email}
                onChange={this.handleChange}
                // helperText={}
                // fullWidth
                margin="normal"
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
              />

              <CssTextField
                error={this.state.phoneNumberError}
                id="phoneNumber"
                name="phoneNumber"
                type="phoneNumber"
                label="+1 (---) --- ----"
                className="ContactTextField"
                variant="outlined"
                value={this.state.phoneNumber}
                onChange={this.handleChange}
                // helperText={}
                // fullWidth
                margin="normal"
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
              />

              <CssTextField
                id="comment"
                name="comment"
                type="comment"
                label="Comment"
                className="ContactTextField"
                variant="outlined"
                value={this.state.comment}
                onChange={this.handleChange}
                // helperText={}
                // fullWidth
                margin="normal"
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
              />

              <p className="contactUsLowerText">
                By pressing “Done” you let us collect your information to give
                you best service
              </p>

              <Button
                type="submit"
                variant="contained"
                color="primary"
                className="DoneButton"
                size="medium"
              >
                <div className="contactUsButtonSubmitText">Done</div>
              </Button>

              <div
                className="contactUsHelperText"
                style={{ color: `${this.state.heplerTextColor}` }}
              >
                {this.state.helperText}
              </div>
            </form>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            style={{ flexDirection: "column", alignSelf: "flex-end" }}
          >
            <img
              src={smallHouse}
              alt={"Miami Appartment"}
              className="smallHouseImage"
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default ContactUsFillUpForm;
