import React from "react";
import "./stylePages/error.css";
import Construction from "../images/Construction.png";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";

import { Helmet } from "react-helmet";

function Error() {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Estates Group - 404</title>
      </Helmet>
      <h1
        className="headerError"
        style={{ fontWeight: "400", fontSize: "3rem" }}
      >
        404
      </h1>
      <h2 style={{ fontWeight: "400" }}>This page is under construction</h2>
      <img
        src={Construction}
        alt="Construction"
        style={{ height: "50vh", width: "50vh" }}
      />
      <div>
        <Button
          className="goBackHome404"
          component={Link}
          to="/properties"
          style={{ backgroundColor: "white", marginTop: "24px" }}
        >
          Go back home
        </Button>
      </div>
      <h2 style={{ fontWeight: "500", fontSize: "1rem" }}>
        Oh no.. My Table.. It's broken
      </h2>
    </div>
  );
}

export default Error;
