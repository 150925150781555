import React, { Component, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

//MUI Stuff
import SwipeableDrawer from "@mui/material/SwipeableDrawer";
import { Button } from "@mui/material";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Divider from "@material-ui/core/Divider";
import axios from "axios";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../store";

function Navbar(props) {
  const [open, setOpen] = useState(false);

  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const setUserCredentials = useCredentialsStore(
    (state) => state.setUserCredentials
  );
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const setAuthentication = useAuthenticatedStore(
    (state) => state.setAuthenticated
  );
  const token = useAuthenticatedStore((state) => state.token);
  const setToken = useAuthenticatedStore((state) => state.setToken);

  const openDrawer = () => {
    setOpen(true);
  };

  const handleCloseDrawer = () => {
    setOpen(false);
  };

  const currentLocation = useLocation().pathname;

  const logout = () => {
    localStorage.removeItem("FBIdToken");
    delete axios.defaults.headers.common["Authorization"];
    setAuthentication(false);
    setUserCredentials({});
    setToken("");
    handleCloseDrawer();
  };

  // color: window.location.pathname !== '/contactus' ? '#000000' : '#F1EDE9'
  // color: this.state.headerColor
  // color: location.pathname === '/contactus' ? '#000000' : '#F1EDE9'

  // console.log(this.props.windowWidth)
  return (
    <div>
      {props.windowWidth > 1100 ? (
        <div className="header">
          <div className="header-element">
            <Button
              component={Link}
              to="/"
              className="navbarNameTab"
              style={{
                textTransform: "none",
                color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                background:
                  currentLocation === "/map"
                    ? "rgba(256,256,256,0.8)"
                    : "transparent",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              home
            </Button>
          </div>
          <div className="header-element">
            <Button
              component={Link}
              to="/map"
              className="navbarNameTab"
              style={{
                textTransform: "none",
                color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                background:
                  currentLocation === "/map"
                    ? "rgba(256,256,256,0.8)"
                    : "transparent",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              map
            </Button>
          </div>
          <div className="header-element">
            <Button
              component={Link}
              to="/properties"
              className="navbarNameTab"
              style={{
                textTransform: "none",
                color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                background:
                  currentLocation === "/map"
                    ? "rgba(256,256,256,0.8)"
                    : "transparent",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              projects
            </Button>
          </div>
          <div className="header-element">
            <Button
              component={Link}
              to="/contactus"
              className="navbarNameTab"
              style={{
                textTransform: "none",
                color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                background:
                  currentLocation === "/map"
                    ? "rgba(256,256,256,0.8)"
                    : "transparent",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
              }}
            >
              contact us
            </Button>
          </div>
          <div className="header-element" style={{ opacity: "50%" }}>
            {authentication === true ? (
              <div>
                <Button
                  onClick={logout}
                  className="navbarNameTab"
                  style={{
                    textTransform: "none",
                    color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                    background:
                      currentLocation === "/map"
                        ? "rgba(256,256,256,0.8)"
                        : "transparent",
                    fontFamily: "Jost",
                    fontWeight: "300",
                    fontSize: "18px",
                  }}
                >
                  Logout
                </Button>
              </div>
            ) : (
              <Button
                component={Link}
                to="/login"
                className="navbarNameTab"
                style={{
                  textTransform: "none",
                  color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
                  background:
                    currentLocation === "/map"
                      ? "rgba(256,256,256,0.8)"
                      : "transparent",
                  fontFamily: "Jost",
                  fontWeight: "300",
                  fontSize: "18px",
                }}
              >
                Login
              </Button>
            )}
          </div>
        </div>
      ) : (
        <div>
          <div
            style={{
              // background: 'rgba(219, 211, 207, 0.5)',
              background:
                currentLocation === "/map"
                  ? "rgba(256,256,256,0.8)"
                  : "rgba(219, 211, 207, 0.5)",
              display: "inline-block",
              borderRadius: "15px",
              paddingLeft: "21px",
              paddingRight: "21px",
              paddingTop: "3px",
              paddingBottom: "3px",
              fontSize: "16px",
              // color: 'white',
              color: currentLocation === "/map" ? "#000000" : "#F1EDE9",
              position: "fixed",
              right: "24px",
              top: "24px",
              zIndex: "1",
            }}
            onClick={openDrawer}
          >
            Menu
          </div>

          <SwipeableDrawer
            anchor="right"
            open={open}
            onClose={() => setOpen(false)}
            // onClose={() => this.setState({open: false})}
            onOpen={() => {}}
            PaperProps={{
              sx: {
                backgroundColor: "#6868AC",
              },
            }}
          >
            <div className="mobile-header">
              <div className="mobile-header-element">
                {/* <Button component={Link} to="/" style={{justifyContent: 'start', color: '#F1EDE9', textTransform: 'none'}}>Home</Button> */}
                <Button
                  component={Link}
                  to="/"
                  style={{
                    justifyContent: "start",
                    color: "#F1EDE9",
                    textTransform: "none",
                  }}
                  onClick={handleCloseDrawer}
                >
                  Home
                </Button>
              </div>
              <div className="mobile-header-element">
                <Button
                  component={Link}
                  to="/map"
                  style={{
                    justifyContent: "start",
                    color: "#F1EDE9",
                    textTransform: "none",
                  }}
                  onClick={handleCloseDrawer}
                >
                  Map
                </Button>
              </div>
              <div className="mobile-header-element">
                <Button
                  component={Link}
                  to="/properties"
                  style={{
                    justifyContent: "start",
                    color: "#F1EDE9",
                    textTransform: "none",
                  }}
                  onClick={handleCloseDrawer}
                >
                  Projects
                </Button>
              </div>
              <div className="mobile-header-element">
                <Button
                  component={Link}
                  to="/contactus"
                  style={{
                    justifyContent: "start",
                    color: "#F1EDE9",
                    textTransform: "none",
                  }}
                  onClick={handleCloseDrawer}
                >
                  Contact us
                </Button>
              </div>
              <Divider style={{ marginTop: "8px" }} />
              <div className="mobile-header-element" style={{ opacity: "50%" }}>
                {authentication === true ? (
                  <div>
                    <div>
                      <Button
                        style={{
                          justifyContent: "start",
                          color: "#F1EDE9",
                          textTransform: "none",
                        }}
                        onClick={logout}
                      >
                        Logout
                      </Button>
                    </div>
                    <div>
                      <Button
                        style={{
                          justifyContent: "start",
                          color: "#F1EDE9",
                          textTransform: "none",
                        }}
                        component={Link}
                        to="/secretpage"
                        onClick={handleCloseDrawer}
                      >
                        Secret page
                      </Button>
                    </div>
                  </div>
                ) : (
                  <Button
                    component={Link}
                    to="/login"
                    style={{
                      justifyContent: "start",
                      color: "#F1EDE9",
                      textTransform: "none",
                    }}
                    onClick={handleCloseDrawer}
                  >
                    Login
                  </Button>
                )}
              </div>
            </div>
          </SwipeableDrawer>
        </div>
      )}
    </div>
  );
}

export default Navbar;

// class Navbar extends Component {
//     constructor(props) {
//         super(props);
//         this.state = {
//             open: false,
//             headerColor: '#F1EDE9',
//         };
//     }

//     openDrawer = () =>{
//         this.setState({open: true})
//     }
//     // color: window.location.pathname !== '/contactus' ? '#000000' : '#F1EDE9'
//     // color: this.state.headerColor
//     // color: location.pathname === '/contactus' ? '#000000' : '#F1EDE9'
//   render() {
//         console.log(this.props.windowWidth)
//     return (
//       <div>
//             {this.props.windowWidth > 1100 ? (
//                 <div className='header'>

//                     <div className='header-element'>
//                         <Button component={Link} to="/" style={{textTransform: "none", color: 'red', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px'}}>home</Button>
//                     </div>
//                     <div className='header-element'>
//                         <Button component={Link} to="/map" style={{textTransform: "none", color: '#F1EDE9', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px'}}>map</Button>
//                     </div>
//                     <div className='header-element'>
//                         <Button component={Link} to="/properties" style={{textTransform: "none", color: '#F1EDE9', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px'}}>properties</Button>
//                     </div>
//                     <div className='header-element'>
//                         <Button component={Link} to="/contactus" style={{textTransform: "none", color: '#F1EDE9', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px'}}>contact us</Button>
//                     </div>
//                     <div className='header-element'
//                     style={{opacity: '50%'}}
//                     >
//                     <Button component={Link} to="/login" style={{textTransform: "none", color: '#F1EDE9', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px'}}>Login</Button>
//                 </div>

//             </div>
//             ) : (
//             <div>
//                 <div
//                 style={{
//                     background: 'rgba(219, 211, 207, 0.5)',
//                     display: 'inline-block',
//                     borderRadius: '15px',
//                     paddingLeft: '21px',
//                     paddingRight: '21px',
//                     paddingTop: '3px',
//                     paddingBottom: '3px',
//                     fontSize: '16px',
//                     color: 'white',
//                     position: 'absolute',
//                     right: '24px',
//                     top: '24px',
//                     zIndex: '1',
//                 }}
//                 onClick = {this.openDrawer}
//                 >Menu</div>

//                 <SwipeableDrawer
//                     anchor="right"
//                     open={this.state.open}
//                     onClose={() => this.setState({open: false})}
//                     onOpen={() => {}}
//                     PaperProps={{
//                         sx: {
//                           backgroundColor: "#6868AC"
//                         }
//                       }}
//                 >
//                     <div className='mobile-header'>
//                         <div className='mobile-header-element'>
//                             <Button component={Link} to="/" style={{justifyContent: 'start', color: '#F1EDE9'}}>Home</Button>
//                         </div>
//                         <div className='mobile-header-element'>
//                             <Button component={Link} to="/map" style={{justifyContent: 'start', color: '#F1EDE9'}}>Map</Button>
//                         </div>
//                         <div className='mobile-header-element'>
//                             <Button component={Link} to="/properties" style={{justifyContent: 'start', color: '#F1EDE9'}}>Properties</Button>
//                         </div>
//                         <div className='mobile-header-element'>
//                             <Button component={Link} to="/contactus" style={{justifyContent: 'start', color: '#F1EDE9'}}>Contact us</Button>
//                         </div>
//                         <Divider style={{marginTop: '8px'}} />
//                         <div className='mobile-header-element'
//                         style={{opacity: '50%'}}
//                         >
//                             <Button component={Link} to="/login" style={{justifyContent: 'start', color: '#F1EDE9'}}>Login</Button>
//                         </div>
//                     </div>

//                 </SwipeableDrawer>
//             </div>
//             )}

//       </div>
//     )
//   }
// }

// export default Navbar;
