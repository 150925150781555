import React, { Component } from "react";

// Images
import MiamiTestimonials1 from "../images/MiamiTestimonials1.jpg";
import MiamiTestimonials2 from "../images/MiamiTestimonials2.jpg";
import MiamiTestimonials3 from "../images/MiamiTestimonials3.jpg";
import MiamiTestimonials4 from "../images/MiamiTestimonials4.jpg";
import MiamiTestimonials5 from "../images/MiamiTestimonials5.jpg";
import MiamiTestimonials6 from "../images/MiamiTestimonials6.jpg";

import Miami11 from "../images/Testimonial1/Tower.jpg";
import Miami12 from "../images/Testimonial1/Plan.png";
import Miami13 from "../images/Testimonial1/Living.jpg";
import Miami14 from "../images/Testimonial1/Kitchen.jpg";
import Miami15 from "../images/Testimonial1/Gym.jpg";
import Miami16 from "../images/Testimonial1/Bathroom.jpg";

import Miami21 from "../images/Testimonial2/Main.jpg";
import Miami22 from "../images/Testimonial2/Plan.jpg";
import Miami23 from "../images/Testimonial2/Living.jpg";
import Miami24 from "../images/Testimonial2/Bed.jpg";
import Miami25 from "../images/Testimonial2/Pool.jpg";
import Miami26 from "../images/Testimonial2/Terrase.jpg";

import Miami31 from "../images/Testimonial3/Main.jpg";
import Miami32 from "../images/Testimonial3/Plan.jpg";
import Miami33 from "../images/Testimonial3/Hall.jpg";
import Miami34 from "../images/Testimonial3/Bed.jpg";
import Miami35 from "../images/Testimonial3/Gym.jpg";
import Miami36 from "../images/Testimonial3/Kitchen.jpg";

import Miami41 from "../images/Testimonial4/Main.jpg";
import Miami42 from "../images/Testimonial4/Plan.jpg";
import Miami43 from "../images/Testimonial4/Bath.jpg";
import Miami44 from "../images/Testimonial4/Bed.jpg";
import Miami45 from "../images/Testimonial4/Living.jpg";
import Miami46 from "../images/Testimonial4/Kitchen.jpg";

//Memoji
import Memoji1 from "../images/Memojies/Memoji1.1.png";
import Memoji2 from "../images/Memojies/Memoji2.1.png";
import Memoji3 from "../images/Memojies/Memoji3.1.png";
import Memoji4 from "../images/Memojies/Memoji4.1.png";

import "../styles/testimonials.css";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../stylesTwo.css";

//MUI
import { Grid } from "@material-ui/core";

export default class Testimonials extends Component {
  render() {
    return (
      <div>
        <h2 className="TestimonialsHeader">Testimonials</h2>

        <div style={{ marginTop: "0px" }}>
          <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 50000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper2"
          >
            <SwiperSlide className="testimonialSwiper2">
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami11}
                      alt={"Miami house"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="TestimonialsPerson">
                      <Grid container>
                        <Grid item xs={4}>
                          <img
                            loading="lazy"
                            src={Memoji2}
                            alt={"Ben Barthel"}
                            className="PortraitTestimonialsImg"
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <h6 className="PersonNameTestimonials">
                            Ben Barthel
                          </h6>
                        </Grid>
                      </Grid>

                      <p className="PersonCommentTestimonials">
                        {/* Phasellus non sem nisl. Nulla ultricies, justo quis venenatis 
                                        hendrerit, urna augue ultrices nulla, quis tincidunt risus orci et risus. */}
                        Oleksandr beyond incredible to work with! He had our
                        back every step of the way which we super appreciated as
                        first time home buyers. We felt confident navigating
                        this tricky housing market knowing we were working with
                        such a dedicated and on the ball agent. Will absolutely
                        be recommending to everyone we know!
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "3px" }}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami12}
                      alt={"Miami backyard"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid
                      container
                      spacing={0}
                      sx={{ justifyContent: "center" }}
                    >
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami13}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami14}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ marginTop: "7px" }}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami15}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami16}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonialSwiper2">
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami21}
                      alt={"Miami house"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="TestimonialsPerson">
                      <Grid container>
                        <Grid item xs={4}>
                          <img
                            loading="lazy"
                            // src={Portrait2}
                            src={Memoji1}
                            alt={"Anastasia Pyatakova"}
                            className="PortraitTestimonialsImg"
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <h6 className="PersonNameTestimonials">
                            Anastasia Pyatakova
                          </h6>
                        </Grid>
                      </Grid>

                      <p className="PersonCommentTestimonials">
                        Alex and Valeria helped me and my husband with our first
                        property and we can not be happier! They were very
                        attentive to our needs, giving maximum attention to the
                        demands. We liked that any question in regards to the
                        market and properties we had they were ready to answer.
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "3px" }}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami22}
                      alt={"Miami backyard"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami23}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami24}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ marginTop: "7px" }}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami25}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami26}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonialSwiper2">
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami31}
                      alt={"Miami house"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="TestimonialsPerson">
                      <Grid container>
                        <Grid item xs={4}>
                          <img
                            loading="lazy"
                            src={Memoji4}
                            alt={"Hudson Jaxon"}
                            className="PortraitTestimonialsImg"
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <h6 className="PersonNameTestimonials">
                            Hudson Jaxon
                          </h6>
                        </Grid>
                      </Grid>

                      <p className="PersonCommentTestimonials">
                        My experience with Valeria of my estates group has been
                        wonderful in every way. She has been 100% professional
                        though also understanding and helpful in every
                        situation. Thank you so much for your respect and care
                        in your work.
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "3px" }}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami32}
                      alt={"Miami backyard"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami33}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami34}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ marginTop: "7px" }}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami35}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami36}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
            <SwiperSlide className="testimonialSwiper2">
              <div>
                <Grid container spacing={1}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami41}
                      alt={"Miami house"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <div className="TestimonialsPerson">
                      <Grid container>
                        <Grid item xs={4}>
                          <img
                            loading="lazy"
                            src={Memoji3}
                            alt={"VC FLEX Centre"}
                            className="PortraitTestimonialsImg"
                          />
                        </Grid>

                        <Grid item xs={8}>
                          <h6 className="PersonNameTestimonials">
                            VC FLEX Centre
                          </h6>
                        </Grid>
                      </Grid>

                      <p className="PersonCommentTestimonials">
                        We were looking for a 2nd shop in close proximity to our
                        main location. Valeria and Alex were able to find 4
                        suitable properties within a few km and within a few
                        days we were able to secure the building. Appreciate
                        your hard work and dedication!
                      </p>
                    </div>
                  </Grid>
                </Grid>

                <Grid container spacing={1} style={{ marginTop: "3px" }}>
                  <Grid item xs={12} md={6}>
                    <img
                      loading="lazy"
                      src={Miami42}
                      alt={"Miami backyard"}
                      className="MiamiTestimonialsImg"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Grid container spacing={0}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami43}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami44}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>

                    <Grid container spacing={0} style={{ marginTop: "7px" }}>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami45}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <img
                          loading="lazy"
                          src={Miami46}
                          alt={"Miami beutiful house"}
                          className="MiamiTestimonialsSmallImg"
                          style={{ marginLeft: "2px" }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    );
  }
}
