import { Button } from '@material-ui/core';
import React, { Component } from 'react';

import bgVideo from '../videos/Miami.mp4'

class Main extends Component {
  render() {

    // this.props.windowWidth
    let mainText=(
      <div>

              <h2 style={{
                        fontSize: '20px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '31.5vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  BUY PRE-CONSTRUCTION IN FLORIDA
              </h2>

              <h1 style={{
                        fontSize: '56px', 
                        fontWeight: '700',
                        position: 'absolute',
                        marginTop: '35vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  MY ESTATES GROUP
              </h1>

              <h3 style={{
                        fontSize: '20px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '43vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  invest in your future
              </h3>

          </div>
    )
    if(this.props.windowWidth > 700) {
      mainText = (
        <div>

              <h2 style={{
                        fontSize: '20px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '31.5vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  BUY PRE-CONSTRUCTION IN FLORIDA
              </h2>

              <h1 style={{
                        fontSize: '56px', 
                        fontWeight: '700',
                        position: 'absolute',
                        marginTop: '35vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  MY ESTATES GROUP
              </h1>

              <h3 style={{
                        fontSize: '20px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '43vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  invest in your future
              </h3>

          </div>
      )
    } else if (this.props.windowWidth < 700 && this.props.windowWidth > 500){
      mainText = (
        <div>

              <h2 style={{
                        fontSize: '18px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '31.5vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  BUY PRE-CONSTRUCTION IN FLORIDA
              </h2>

              <h1 style={{
                        fontSize: '48px', 
                        fontWeight: '700',
                        position: 'absolute',
                        marginTop: '35vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  MY ESTATES GROUP
              </h1>

              <h3 style={{
                        fontSize: '18px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '43vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  invest in your future
              </h3>

          </div>
      )
    } else if( this.props.windowWidth < 500){
      mainText = (
        <div>

              <h2 style={{
                        fontSize: '16px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '25vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  BUY PRE-CONSTRUCTION IN FLORIDA
              </h2>

              <h1 style={{
                        fontSize: '36px', 
                        fontWeight: '700',
                        position: 'absolute',
                        marginTop: '28vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  MY ESTATES GROUP
              </h1>

              <h3 style={{
                        fontSize: '16px', 
                        fontWeight: '400',
                        position: 'absolute',
                        marginTop: '33vh',
                        left: '0',
                        right: '0',
                        zIndex: '1',
                      }}
              className='Cormorant'
              > 
                  invest in your future
              </h3>

          </div>
      )
    }

    return (
      <div >

          {/* Text */}
          {mainText}

          <div className='overlay'></div>
          <video autoPlay={true} muted={true} playsInline={true} loop={true} className='miamiVideo'>
            <source src={bgVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>

      </div>
    );
  }
}

export default Main