import React, { Component } from "react";
import { Link } from "react-router-dom";

//External files
import miami1 from "../images/OnePark1.jpg";
import miami2 from "../images/Diesel1.jpg";
import miami3 from "../images/Vida1.jpg";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "../styles.css";

//MUI
import Grid from "@mui/material/Grid";

import AOS from "aos";

class FeaturedProjects extends Component {
  render() {
    let carousel;

    carousel = (
      <div style={{ marginTop: "-30px" }}>
        <Swiper
          spaceBetween={30}
          centeredSlides={true}
          autoplay={{
            delay: 5000,
            disableOnInteraction: false,
          }}
          pagination={{
            clickable: true,
          }}
          navigation={true}
          modules={[Autoplay, Pagination, Navigation]}
          className="mySwiper"
        >
          <SwiperSlide>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "75px",
                  }}
                >
                  <img
                    src={miami1}
                    alt={"One Park Tower"}
                    style={{
                      borderRadius: "10px",
                      width: "550px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "-150px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "450px",
                      height: "290px",
                      marginTop: "0px",
                      paddingBottom: "20px",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "48px",
                        paddingTop: "32px",
                        fontWeight: "300",
                        fontSize: "24px",
                        marginBottom: "0px",
                      }}
                    >
                      One Park Tower
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          967 - 3213 ft
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginRight: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          795 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "354px",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                      }}
                    >
                      Just 20 minutes from Miami Beach this community will offer
                      everything you could possibly need, including a perfect
                      mix of vibrant, walkable urban retail areas, lively
                      entertainment, acres of green spaces and parks, children’s
                      playgrounds, and serene natural features – all while also
                      being connected to greater Miami.
                    </p>
                    <Link to={`properties/Wa0zYF478jIReRuOd8mI`}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "48px",
                          fontWeight: "300",
                          fontSize: "20px",
                          marginBottom: "0px",
                          color: "#6868AC",
                        }}
                      >
                        Details
                      </h5>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "75px",
                  }}
                >
                  <img
                    src={miami2}
                    alt={"miami house"}
                    style={{
                      borderRadius: "10px",
                      width: "550px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "-150px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "450px",
                      height: "290px",
                      marginTop: "0px",
                      paddingBottom: "20px",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "48px",
                        paddingTop: "32px",
                        fontWeight: "300",
                        fontSize: "24px",
                        marginBottom: "0px",
                      }}
                    >
                      Diesel Wynwood
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          405 - 1437 ft
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginRight: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          835 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "354px",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                      }}
                    >
                      Breaking expectations doesn’t mean sacrificing enjoyment.
                      That’s not the Diesel way. High-energy pursuits, laid-back
                      leisure, connected socializing, deep relaxation, boundless
                      creativity
                    </p>
                    <Link to={`properties/HyeXDzJzBS6SKSGwg5H2`}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "48px",
                          fontWeight: "300",
                          fontSize: "20px",
                          marginBottom: "0px",
                          color: "#6868AC",
                        }}
                      >
                        Details
                      </h5>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div>
              <Grid container>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "75px",
                  }}
                >
                  <img
                    src={miami3}
                    alt={"Vida Edgewater"}
                    style={{
                      borderRadius: "10px",
                      width: "550px",
                    }}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  style={{
                    marginLeft: "-150px",
                  }}
                >
                  <div
                    style={{
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "450px",
                      height: "290px",
                      marginTop: "0px",
                      paddingBottom: "20px",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "48px",
                        paddingTop: "32px",
                        fontWeight: "300",
                        fontSize: "24px",
                        marginBottom: "0px",
                      }}
                    >
                      Vida Edgewater
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          387 - 1780 ft
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            justifyContent: "end",
                            marginRight: "48px",
                            fontSize: "18px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          325 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "354px",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                      }}
                    >
                      Edgewater is a small coastal slice of mainland Miami.
                      Smart-home systems, valet parking, HiTech coworking
                      center, 24/7 services, pool & jacuzzi and more makes this
                      place perfect!
                    </p>
                    <Link to={`properties/ijO2WDGmGZQxluA7aUFi`}>
                      <h5
                        style={{
                          display: "flex",
                          justifyContent: "flex-end",
                          marginRight: "48px",
                          fontWeight: "300",
                          fontSize: "20px",
                          marginBottom: "0px",
                          color: "#6868AC",
                        }}
                      >
                        Details
                      </h5>
                    </Link>
                  </div>
                </Grid>
              </Grid>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    );

    // if (this.props.windowWidth > 1163){
    if (this.props.windowWidth > 898) {
      carousel = (
        <div style={{ marginTop: "-30px" }}>
          <Swiper
            rewind={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "75px",
                    }}
                  >
                    <img
                      src={miami1}
                      alt={"One Park Tower"}
                      style={{
                        borderRadius: "10px",
                        width: "550px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "-150px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        borderRadius: "10px",
                        width: "450px",
                        height: "290px",
                        marginTop: "0px",
                        paddingBottom: "20px",
                      }}
                    >
                      <h4
                        style={{
                          justifyContent: "start",
                          display: "flex",
                          marginLeft: "48px",
                          paddingTop: "32px",
                          fontWeight: "300",
                          fontSize: "24px",
                          marginBottom: "0px",
                        }}
                      >
                        One Park Tower
                      </h4>

                      <Grid container>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              marginLeft: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            967 - 3213 ft
                          </p>
                        </Grid>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginRight: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            795 000 $
                          </p>
                        </Grid>
                      </Grid>

                      <p
                        style={{
                          width: "354px",
                          textAlign: "left",
                          fontWeight: "300",
                          fontSize: "18px",
                          display: "inline-block",
                          marginTop: "16px",
                        }}
                      >
                        Perfect mix of vibrant, walkable urban retail areas,
                        lively entertainment, acres of green spaces and parks,
                        children’s playgrounds, and serene natural features.
                      </p>
                      <Link to={`properties/Wa0zYF478jIReRuOd8mI`}>
                        <h5
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "48px",
                            fontWeight: "300",
                            fontSize: "20px",
                            marginBottom: "0px",
                            color: "#6868AC",
                          }}
                        >
                          Details
                        </h5>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "75px",
                    }}
                  >
                    <img
                      src={miami2}
                      alt={"Diesel Wynwood"}
                      style={{
                        borderRadius: "10px",
                        width: "550px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "-150px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        borderRadius: "10px",
                        width: "450px",
                        height: "290px",
                        marginTop: "0px",
                        paddingBottom: "20px",
                      }}
                    >
                      <h4
                        style={{
                          justifyContent: "start",
                          display: "flex",
                          marginLeft: "48px",
                          paddingTop: "32px",
                          fontWeight: "300",
                          fontSize: "24px",
                          marginBottom: "0px",
                        }}
                      >
                        Diesel Wynwood
                      </h4>

                      <Grid container>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              marginLeft: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            405 - 1437 ft
                          </p>
                        </Grid>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginRight: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            835 000 $
                          </p>
                        </Grid>
                      </Grid>

                      <p
                        style={{
                          width: "354px",
                          textAlign: "left",
                          fontWeight: "300",
                          fontSize: "18px",
                          display: "inline-block",
                          marginTop: "16px",
                        }}
                      >
                        Breaking expectations doesn’t mean sacrificing
                        enjoyment. High-energy pursuits, laid-back leisure,
                        connected socializing, deep relaxation, boundless
                        creativity
                      </p>
                      <Link to={`properties/HyeXDzJzBS6SKSGwg5H2`}>
                        <h5
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "48px",
                            fontWeight: "300",
                            fontSize: "20px",
                            marginBottom: "0px",
                            color: "#6868AC",
                          }}
                        >
                          Details
                        </h5>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Grid container>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "75px",
                    }}
                  >
                    <img
                      src={miami3}
                      alt={"Vida Edgewater"}
                      style={{
                        borderRadius: "10px",
                        width: "550px",
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    xs={6}
                    style={{
                      marginLeft: "-150px",
                    }}
                  >
                    <div
                      style={{
                        backgroundColor: "rgba(0, 0, 0, 0.75)",
                        borderRadius: "10px",
                        width: "450px",
                        height: "290px",
                        marginTop: "0px",
                        paddingBottom: "20px",
                      }}
                    >
                      <h4
                        style={{
                          justifyContent: "start",
                          display: "flex",
                          marginLeft: "48px",
                          paddingTop: "32px",
                          fontWeight: "300",
                          fontSize: "24px",
                          marginBottom: "0px",
                        }}
                      >
                        Vida Edgewater
                      </h4>

                      <Grid container>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              marginLeft: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            387 - 1780 ft
                          </p>
                        </Grid>
                        <Grid item xs={6}>
                          <p
                            style={{
                              display: "flex",
                              justifyContent: "end",
                              marginRight: "48px",
                              fontSize: "18px",
                              fontWeight: "300",
                              marginBottom: "0px",
                              marginTop: "4px",
                              opacity: "80%",
                            }}
                          >
                            325 000 $
                          </p>
                        </Grid>
                      </Grid>

                      <p
                        style={{
                          width: "354px",
                          textAlign: "left",
                          fontWeight: "300",
                          fontSize: "18px",
                          display: "inline-block",
                          marginTop: "16px",
                        }}
                      >
                        Edgewater is a small coastal slice of mainland Miami.
                        Smart-home systems, valet parking, HiTech coworking
                        center, 24/7 services, pool & jacuzzi and more makes
                        this place perfect!
                      </p>
                      <Link to={`properties/ijO2WDGmGZQxluA7aUFi`}>
                        <h5
                          style={{
                            display: "flex",
                            justifyContent: "flex-end",
                            marginRight: "48px",
                            fontWeight: "300",
                            fontSize: "20px",
                            marginBottom: "0px",
                            color: "#6868AC",
                          }}
                        >
                          Details
                        </h5>
                      </Link>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      );
    } else if (this.props.windowWidth <= 898) {
      carousel = (
        <div style={{ marginTop: "20px" }}>
          <Swiper
            rewind={true}
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            pagination={{
              clickable: true,
            }}
            navigation={true}
            modules={[Autoplay, Pagination, Navigation]}
            className="mySwiper"
          >
            <SwiperSlide>
              <div>
                <Link
                  to={`properties/Wa0zYF478jIReRuOd8mI`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={miami1}
                    alt={"miami house"}
                    style={{
                      borderRadius: "10px",
                      width: "90vw",
                      zIndex: "1",
                      marginBottom: "-50px",
                    }}
                  />

                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "80vw",
                      height: "290px",
                      marginTop: "0px",
                      // paddingBottom: '20px',
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: "3",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "24px",
                        paddingTop: "24px",
                        fontWeight: "300",
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                    >
                      One Park Tower
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          967 - 3213 ft <sup>2</sup>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            // display: 'flex',
                            // justifyContent: 'end',
                            marginRight: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                            textAlign: "right",
                          }}
                        >
                          795 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "87%",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                        marginLeft: "24px",
                        marginRight: "24px",
                        height: "135px",
                      }}
                    >
                      Perfect mix of vibrant, walkable urban retail areas,
                      lively entertainment, acres of green spaces and parks,
                      children’s playgrounds, and serene natural features.
                    </p>

                    <h5
                      style={{
                        // display: 'flex',
                        // justifyContent: 'end',
                        // display: 'block',
                        // position: 'absolute',
                        // right: '24px',
                        // bottom: '24px',
                        // marginRight: '7%',
                        marginRight: "24px",
                        textAlign: "right",
                        fontWeight: "300",
                        fontSize: "20px",
                        // marginBottom: '24px',
                        marginTop: "0px",
                        color: "#6868AC",
                      }}
                    >
                      Details
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Link
                  to={`properties/HyeXDzJzBS6SKSGwg5H2`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={miami2}
                    alt={"Diesel Wynwood"}
                    style={{
                      borderRadius: "10px",
                      width: "90vw",
                      zIndex: "1",
                      marginBottom: "-50px",
                    }}
                  />

                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "80vw",
                      height: "290px",
                      marginTop: "0px",
                      // paddingBottom: '20px',
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: "3",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "24px",
                        paddingTop: "24px",
                        fontWeight: "300",
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                    >
                      Diesel Wynwood
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          405 - 1437 ft <sup>2</sup>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            // display: 'flex',
                            // justifyContent: 'end',
                            marginRight: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                            textAlign: "right",
                          }}
                        >
                          835 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "87%",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                        marginLeft: "24px",
                        marginRight: "24px",
                        height: "135px",
                      }}
                    >
                      Breaking expectations doesn’t mean sacrificing enjoyment.
                      High-energy pursuits, laid-back leisure, connected
                      socializing, deep relaxation, boundless creativity
                    </p>

                    <h5
                      style={{
                        // display: 'flex',
                        // justifyContent: 'end',
                        // display: 'block',
                        // position: 'absolute',
                        // right: '24px',
                        // bottom: '24px',
                        // marginRight: '7%',
                        marginRight: "24px",
                        textAlign: "right",
                        fontWeight: "300",
                        fontSize: "20px",
                        // marginBottom: '24px',
                        marginTop: "0px",
                        color: "#6868AC",
                      }}
                    >
                      Details
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div>
                <Link
                  to={`properties/ijO2WDGmGZQxluA7aUFi`}
                  style={{
                    textDecoration: "none",
                  }}
                >
                  <img
                    src={miami3}
                    alt={"Vida Edgewater"}
                    style={{
                      borderRadius: "10px",
                      width: "90vw",
                      zIndex: "1",
                      marginBottom: "-50px",
                    }}
                  />

                  <div
                    style={{
                      position: "relative",
                      backgroundColor: "rgba(0, 0, 0, 0.75)",
                      borderRadius: "10px",
                      width: "80vw",
                      height: "290px",
                      marginTop: "0px",
                      // paddingBottom: '20px',
                      marginLeft: "auto",
                      marginRight: "auto",
                      zIndex: "3",
                    }}
                  >
                    <h4
                      style={{
                        justifyContent: "start",
                        display: "flex",
                        marginLeft: "24px",
                        paddingTop: "24px",
                        fontWeight: "300",
                        fontSize: "20px",
                        marginBottom: "0px",
                      }}
                    >
                      Vida Edgewater
                    </h4>

                    <Grid container>
                      <Grid item xs={6}>
                        <p
                          style={{
                            display: "flex",
                            marginLeft: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                          }}
                        >
                          387 - 1780 ft <sup>2</sup>
                        </p>
                      </Grid>
                      <Grid item xs={6}>
                        <p
                          style={{
                            // display: 'flex',
                            // justifyContent: 'end',
                            marginRight: "24px",
                            fontSize: "15px",
                            fontWeight: "300",
                            marginBottom: "0px",
                            marginTop: "4px",
                            opacity: "80%",
                            textAlign: "right",
                          }}
                        >
                          325 000 $
                        </p>
                      </Grid>
                    </Grid>

                    <p
                      style={{
                        width: "87%",
                        textAlign: "left",
                        fontWeight: "300",
                        fontSize: "18px",
                        display: "inline-block",
                        marginTop: "16px",
                        marginLeft: "24px",
                        marginRight: "24px",
                        height: "135px",
                      }}
                    >
                      Edgewater is a small coastal slice of mainland Miami.
                      Smart-home systems, valet parking, HiTech coworking
                      center, 24/7 services, pool & jacuzzi and more makes this
                      place perfect!
                    </p>

                    <h5
                      style={{
                        // display: 'flex',
                        // justifyContent: 'end',
                        // display: 'block',
                        // position: 'absolute',
                        // right: '24px',
                        // bottom: '24px',
                        // marginRight: '7%',
                        marginRight: "24px",
                        textAlign: "right",
                        fontWeight: "300",
                        fontSize: "20px",
                        // marginBottom: '24px',
                        marginTop: "0px",
                        color: "#6868AC",
                      }}
                    >
                      Details
                    </h5>
                  </div>
                </Link>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
      );
    }

    return (
      <div>
        <h2
          className="featuredHeader"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          Featured Projects
        </h2>

        {carousel}
      </div>
    );
  }
}

export default FeaturedProjects;
