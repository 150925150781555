import React, { Component } from "react";

//Components
import Main from "../components/Main";
import FeaturedProjects from "../components/FeaturedProjects";
import Team from "../components/Team";
import WhyToBuy from "../components/WhyToBuy";
import NextSteps from "../components/NextSteps";
import ContactUsFillUpForm from "../components/ContactUsFillUpForm";
import FreeConsultation from "../components/FreeConsultation";
import Testimonials from "../components/Testimonials";
import SocialMedia from "../components/SocialMedia";
import GetPreApproval from "../components/GetPreApproval";
import Footer from "../components/Footer";

import { Helmet } from "react-helmet";

class Home extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Estates Group</title>
        </Helmet>
        <Main windowWidth={this.props.windowWidth} />
        <FeaturedProjects windowWidth={this.props.windowWidth} />
        <Team windowWidth={this.props.windowWidth} />
        <WhyToBuy windowWidth={this.props.windowWidth} />
        <NextSteps windowWidth={this.props.windowWidth} />
        <ContactUsFillUpForm windowWidth={this.props.windowWidth} />
        <FreeConsultation windowWidth={this.props.windowWidth} />
        <Testimonials windowWidth={this.props.windowWidth} />
        <SocialMedia windowWidth={this.props.windowWidth} />
        <GetPreApproval windowWidth={this.props.windowWidth} />
        <Footer windowWidth={this.props.windowWidth} />
        {/* <div style={{height: '100vh'}}></div> */}
      </div>
    );
  }
}

export default Home;
