import React, { useEffect, useState } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
// var relativeTime = require('dayjs/plugin/relativeTime')
import relativeTime from "dayjs/plugin/relativeTime";
import { useNavigate } from "react-router-dom";

import "./stylePages/property.css";
import Footer from "../components/Footer";
import MiniMap from "./extrasForPages/MiniMap";
import ImageSlider from "./extrasForPages/ImageSlider";
import FloorPlansSlider from "./extrasForPages/FloorPlansSlider";
import ProfitCalculator from "./extrasForPages/ProfitCalculator";
import Chart from "./extrasForPages/Chart";

// IMAGES
import TikTok from "../images/VectorTikTok.svg";
import FB from "../images/VectorFB.svg";
import Inst from "../images/VectorInst.svg";

// MUI
import { Grid } from "@material-ui/core";
import Button from "@mui/material/Button";
import { withStyles } from "@material-ui/core";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";

//Zustand
import { useAuthenticatedStore } from "../store";
import { Helmet } from "react-helmet";

const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(90deg, #6868AC 0%, rgba(255, 255, 255, 0.5) 100%)",
    color: "#fff",
    border: "solid 1px rgba(255,255,255, 0.25)",
    transitionDuration: "2s",
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(104, 104, 172, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%)",
      color: "black",
    },
  },
})(Button);

function Property(props) {
  const navigate = useNavigate();
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  dayjs.extend(relativeTime);

  const params = useParams();
  const [estate, setEstate] = useState("");
  useEffect(() => {
    // setTimeout( ()=>{ alert('hello'); }, 2000);

    axios
      .get(`/property/${params.propertyId}`)
      .then((res) => {
        console.log(res.data);
        setEstate(res.data);
      })
      .catch((err) => {
        console.log(err);
        navigate("/404");
      });
  }, []);

  console.log("Id from params:" + params.propertyId);
  // const tmp = props.data.filter(obj => {
  //     return obj.propertyId === params.propertyId;
  // }) // CHANGE THIS LATER TO API CALL GET_ONE_PROPERTY
  // const estate = {...tmp[0]};
  console.log(estate);
  const googleMapLink = `https://www.google.com/maps/search/?api=1&query=${estate.latitude}%2C${estate.longitude}`;
  const googleMapImage = estate.mapImage;
  // // https://www.google.com/maps/search/?api=1&query=47.5951518%2C-122. //idk what it is
  // // latitude={"latitude"} longitude={"longitude"} //idk what it is

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  return (
    <div>
      {/* property */}
      {/* {params.id}
        {props.data.houses[params.id].type} */}
      {estate ? (
        <div>
          <div
            style={{
              marginTop: "-80px",
              backgroundImage: `url(${estate.mainImage})`,
            }}
            className="mainImageProperty"
          >
            {/* <img src={estate.images[1]} alt="Apartment 1" className='mainImageProperty' /> */}

            <h3 className="estatePropertyType">{estate.type}</h3>
            <h4 className="estatePropertyStreet">{estate.address}</h4>
          </div>
          <Grid container spacing={0}>
            <Grid item xs={12} md={6}>
              <Grid container spacing={0}>
                <Grid item xs={1} md={3}></Grid>
                <Grid item xs={3} md={3}>
                  <h6 className="estatePropertyQuality">
                    {estate.minBeds}-{estate.maxBeds}
                  </h6>
                  <p className="estatePropertyQualityName">Bedrooms</p>
                  <div
                    className="estateHorizontalLine"
                    // style={{height: '0.5px', backgroundColor: '#DBD3CF', width: '90%', paddingRight: '-50px'}}
                  ></div>
                </Grid>
                <Grid item xs={3} md={3}>
                  <h6 className="estatePropertyQuality">
                    {estate.minBath}-{estate.maxBath}
                  </h6>
                  <p className="estatePropertyQualityName">Bathrooms</p>
                  <div
                    className="estateHorizontalLine"
                    // style={{height: '0.5px', backgroundColor: '#DBD3CF', width: '100%'}}
                  ></div>
                </Grid>
                <Grid item xs={4} md={3}>
                  <h6
                    className="estatePropertyQuality"
                    style={{ marginTop: "40px" }}
                  >
                    {estate.minSize}+ Ft
                    <sup style={{ fontSize: "16px" }}>2</sup>
                  </h6>
                  <p className="estatePropertyQualityName">Total size</p>
                  <div
                    className="estateHorizontalLine"
                    // style={{height: '0.5px', backgroundColor: '#DBD3CF', width: '90%'}}
                  ></div>
                </Grid>
                <Grid item xs={1} md={false}></Grid>
                <div className="estateHorizontalLineMobile" />
              </Grid>
              <div className="propertyQualityBackground">
                <Grid container spacing={0}>
                  <Grid item xs={2} sm={2} md={4}></Grid>
                  <Grid
                    item
                    xs={10}
                    sm={4}
                    md={4}
                    className="estateDetailFeaturesGrid"
                  >
                    <h5
                      style={{
                        fontWeight: "500",
                        fontSize: "24px",
                        marginBottom: "16px",
                      }}
                    >
                      Details
                    </h5>
                    {estate.details.map(function (item, i) {
                      console.log("mapping details");
                      return (
                        <li
                          className="listDetails"
                          style={{ listStyle: "none", marginTop: "8px" }}
                          key={i}
                        >
                          - {item}
                        </li>
                      );
                    })}
                  </Grid>
                  <Grid item xs={2} sm={1} md={1}></Grid>
                  <Grid
                    item
                    xs={10}
                    sm={5}
                    md={3}
                    className="estateDetailFeaturesGrid"
                  >
                    <h5
                      style={{
                        fontWeight: "500",
                        fontSize: "24px",
                        marginBottom: "16px",
                      }}
                    >
                      Features
                    </h5>
                    {estate.features.map(function (item, i) {
                      console.log("test");
                      return (
                        <li
                          className="listDetails"
                          style={{ listStyle: "none", marginTop: "8px" }}
                          key={i}
                        >
                          - {item}
                        </li>
                      );
                    })}
                  </Grid>
                  {/* <Grid item xs={1} md={false}></Grid> */}
                </Grid>
              </div>

              <Grid container spacing={0}>
                <Grid item xs={false} md={4}></Grid>
                <Grid item xs={12} md={8}>
                  <p
                    className="estatesDescription"
                    style={{ marginBottom: "-40px" }}
                  >
                    Name: {estate.name}
                  </p>
                  <p
                    className="estatesDescription"
                    style={{ marginBottom: "-24px" }}
                  >
                    Delivery: {estate.delivery}
                  </p>
                  <p className="estatesDescription">{estate.description}</p>
                </Grid>
                {/* <Grid item md={1}></Grid> */}
              </Grid>

              <Grid container spacing={0}>
                <Grid item md={5} xs={0}></Grid>
                <Grid item xs={12} md={6}>
                  <h6 className="propertyFullPrice">
                    <div style={{ opacity: "100%", display: "inline-block" }}>
                      Price:
                    </div>{" "}
                    ${estate.minPrice}
                  </h6>
                  {/* <ProfitCalculator
                    price={estate.minPrice}
                    index={estate.profitIndex}
                  /> */}
                  <Chart />
                </Grid>
              </Grid>

              {/* Social media buttons + LIKE this property */}
              <Grid container spacing={0} style={{ marginTop: "40px" }}>
                <Grid item md={5} xs={false} />
                <Grid item xs={4} md={2}>
                  <a
                    href="https://www.tiktok.com/@myestatesgroup"
                    target="_blank"
                  >
                    <img src={TikTok} alt={"TikTok icon"} />
                  </a>
                </Grid>
                <Grid item xs={4} md={2}>
                  <a
                    href="https://www.instagram.com/myestatesgroup"
                    target="_blank"
                  >
                    <img src={Inst} alt={"Inst icon"} />
                  </a>
                </Grid>
                <Grid item xs={4} md={2}>
                  <a
                    href="https://www.facebook.com/myestatesgroup/"
                    target="_blank"
                  >
                    <img src={FB} alt={"FB icon"} />
                  </a>
                </Grid>
                <Grid item xs={false} md={1} />
              </Grid>

              {/* View other houses on left and => */}
              {/* Contact us Button => Opens Popover with email and phone number of Alex */}
            </Grid>
            <Grid item xs={12} md={6}>
              {/* <img
                src={estate.floorPlanImage}
                alt="Floor plan"
                className="estatesFloorPlan"
              /> */}
              {estate.floorPlanImage.length > 1 ? (
                <FloorPlansSlider images={estate.floorPlanImage} />
              ) : (
                <img
                  src={estate.floorPlanImage[0]}
                  alt="Floor plan"
                  className="estatesFloorPlan"
                />
              )}
              {/* <FloorPlansSlider images={estate.floorPlanImage} /> */}

              <div>
                <h3 style={{ display: "inline-block", marginRight: "24px" }}>
                  There is more floor plans. Get them by contacting us:
                </h3>
                <Button
                  aria-describedby={id}
                  className="propertyLowerButton"
                  onClick={handleClick}
                >
                  Contact us
                </Button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 2 }}>
                    {/* <a href="tel:+16477792794"><h6 className='propertyContactUsPhoneNum'>+1 (647) 779 - 2794</h6></a> */}
                    <a href="tel:+16472877555">
                      <h6 className="propertyContactUsPhoneNum">
                        +1 (647) 287 - 7555
                      </h6>
                    </a>
                    <a href="mailto:oleksandr@myestatesgroup.com">
                      <h6 className="propertyContactUsPhoneNum">
                        oleksandr@myestatesgroup.com
                      </h6>
                    </a>
                  </Typography>
                </Popover>
              </div>

              {/* EVERYTHING */}
              {/* Images */}
              <MiniMap image={googleMapImage} link={googleMapLink} />
              <ImageSlider images={estate.images} />

              <div className="propertyBottomLineDevider" />

              {/* <h6 className='propertyFullPrice'><div style={{opacity: '90%', display: 'inline-block'}}>Price:</div> ${estate.minPrice}</h6> Maybe move it to right column */}

              {/* ADD TIMEFRAME HERE */}
              <p style={{ color: "#6868AC", opacity: "65%" }}>
                Added {dayjs(estate.createdAt).fromNow()}
              </p>

              <Grid container spacing={0} style={{ marginTop: "40px" }}>
                <Grid item xs={1} sm={2} />
                <Grid item xs={6} sm={4}>
                  <Button
                    component={Link}
                    to="/properties"
                    className="propertyLowerButton"
                  >
                    View other properties
                  </Button>
                </Grid>
                <Grid item xs={5} sm={5}>
                  <Button
                    aria-describedby={id}
                    className="propertyLowerButton"
                    onClick={handleClick}
                  >
                    Contact us
                  </Button>
                  <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClose={handleClose}
                    anchorOrigin={{
                      vertical: "bottom",
                      horizontal: "left",
                    }}
                  >
                    <Typography sx={{ p: 2 }}>
                      {/* <a href="tel:+16477792794"><h6 className='propertyContactUsPhoneNum'>+1 (647) 779 - 2794</h6></a> */}
                      <a href="tel:+16472877555">
                        <h6 className="propertyContactUsPhoneNum">
                          +1 (647) 287 - 7555
                        </h6>
                      </a>
                      <a href="mailto:oleksandr@myestatesgroup.com">
                        <h6 className="propertyContactUsPhoneNum">
                          oleksandr@myestatesgroup.com
                        </h6>
                      </a>
                    </Typography>
                  </Popover>
                </Grid>
                <Grid item xs={1} sm={1} />
              </Grid>
            </Grid>
          </Grid>
          {/* <div style={{height: '100vh'}}/> */}

          <Footer />
        </div>
      ) : (
        <p>Loading...</p>
      )}
    </div>
  );
}

export default Property;

// "https://images.pexels.com/photos/20787/pexels-photo.jpg?auto=compress&cs=tinysrgb&h=350"
