import React, { useState } from "react";
import axios from "axios";

//Zustand
// import { useAuthenticatedStore, useCredentialsStore } from "../store";

function SecretPage() {
  //   const token = useAuthenticatedStore((state) => state.token);
  const [email, setEmail] = useState("");
  const [secretCode, setSecretCode] = useState("");
  const [status, setStatus] = useState(true);

  const emailHandler = (event) => {
    setEmail(event.target.value);
  };

  const secretCodeHandler = (event) => {
    setSecretCode(event.target.value);
  };

  const statusHandler = (event) => {
    setStatus(event.target.value);
  };

  const handleSubmit = (event) => {
    // axios.defaults.headers.common["Authorization"] = token;
    // alert("clicked");
    event.preventDefault();
    let tmp = status === "true" ? true : false;
    let data = { password: secretCode, status: tmp };
    console.log(data);
    axios
      .post(`/changeStatus/${email}`, data)
      .then((res) => {
        alert(
          `${email} Changed to ${status === "true" ? "Admin" : "Not admin"}`
        );
        // setEmail("");
        // setSecretCode("");
      })
      .catch((err) => {
        alert(err);
      });
  };
  return (
    <div>
      <h1>Secret page</h1>
      <h2>Change user status</h2>
      <div>
        <input
          value={email}
          onChange={emailHandler}
          placeholder="Email"
          type="email"
          style={{ marginTop: "-20px" }}
        />

        <input
          value={secretCode}
          onChange={secretCodeHandler}
          placeholder="Secret code"
          type="password"
        />
      </div>
      <div>
        <select name="status" id="status" onChange={statusHandler}>
          <option value={true}>Make admin</option>
          <option value={false}>Remove admin</option>
        </select>
      </div>
      <button
        style={{ marginTop: "40px", fontSize: "20px" }}
        onClick={handleSubmit}
      >
        Submit
      </button>
    </div>
  );
}

export default SecretPage;
