import React, { Component } from "react";
import Step from "./elements/Step";

// images
import AlexLera from "../images/AlexLera.png";

// MUI
import Grid from "@mui/material/Grid";

import AOS from "aos";

class NextSteps extends Component {
  render() {
    return (
      <div>
        <Grid container spacing={0}>
          <Grid item md={6} xs={12}>
            <h2
              className="teamHeader"
              data-aos="fade-up"
              data-aos-anchor-placement="top-bottom"
            >
              Next Steps
            </h2>
            <Step
              countNumber={"1"}
              text={"Call or Book a video call with one of our team members"}
              headerText={"Get in touch"}
              windowWidth={this.props.windowWidth}
            />
            <Step
              countNumber={"2"}
              text={"Pick your project from many projects & floorplans"}
              headerText={"Choose your preferred area"}
              windowWidth={this.props.windowWidth}
            />
            <Step
              countNumber={"3"}
              text={"Choose your exclusive deal provided by our team"}
              headerText={"Get the best price"}
              windowWidth={this.props.windowWidth}
            />
            <Step
              countNumber={"4"}
              text={"We prepare all documents hussle free for you"}
              headerText={"Secure the best floor plan"}
              windowWidth={this.props.windowWidth}
            />
            <Step
              countNumber={"5"}
              text={"Choose your style"}
              headerText={"Design appointment"}
              windowWidth={this.props.windowWidth}
              style={{ textAlign: "left" }}
            />
            <Step
              countNumber={"6"}
              text={"Congratulations on your property"}
              headerText={"Get the keys"}
              windowWidth={this.props.windowWidth}
            />
          </Grid>
          <Grid item md={6} xs={12} style={{ alignSelf: "end" }}>
            {/* <div style={{
                width: '100px',
                height: '100px',
                position: 'absolute',
                backgroundColor: 'red',
                margin: 'auto',
              }}/> */}
            <img
              src={AlexLera}
              alt={"Oleksandr and Valeria"}
              className="AlexLeraImage"
              style={{
                maxWidth: "100%",
                // maxWidth:'106%',
                maxHeight: "100vh",
              }}
            />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default NextSteps;
