import React, { Component } from "react";

import "../styles/socialMedia.css";
import FacebookPost from "./elements/FacebookPost";

// Images
import AlexLeraCircle from "../images/AlexLeraCircle2.png";
import VectorInst from "../images/VectorInst.svg";
import VectorTikTok from "../images/VectorTikTok.svg";
import VectorFB from "../images/VectorFB.svg";
import gif2 from "../gifs/2.gif";
import House1 from "../gifs/House1.gif";
import House2 from "../gifs/House2.gif";
import House3 from "../gifs/House3.gif";
import House5 from "../gifs/House5.jpeg";
import House7 from "../gifs/House7.gif";
import House8 from "../gifs/House8.gif";
import House9 from "../gifs/House9.png";
import House10 from "../gifs/House10.png";
import House12 from "../gifs/House12.png";
import Inst1 from "../instImg/Inst1.jpg";
import Inst2 from "../instImg/Inst2.jpg";
import Inst3 from "../instImg/Inst3.jpg";
import Inst4 from "../instImg/Inst4.jpg";
import Inst5 from "../instImg/Inst5.jpg";
import Inst6 from "../instImg/Inst6.jpg";
import Inst7 from "../instImg/Inst7.jpg";
import Inst8 from "../instImg/Inst8.jpg";
import Inst9 from "../instImg/Inst9.jpg";
import Inst10 from "../instImg/Inst10.jpg";
import Inst11 from "../instImg/Inst11.jpg";
import Inst12 from "../instImg/Inst12.jpg";

// MUI
import Grid from "@mui/material/Grid";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";

const instImages = [
  {
    // img: inst1,
    img: Inst1,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    // img: inst2,
    img: Inst2,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst8, //change
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst4,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst5,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst6, //change
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst7,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst3,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst9,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst10,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst11,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
  {
    img: Inst12,
    link: "https://www.instagram.com/myestatesgroup/",
    title: "Instagram",
  },
];

const tiktokImages = [
  {
    img: House1,
    link: "https://www.tiktok.com/@myestatesgroup/video/7121110661727325445?is_from_webapp=1&sender_device=pc&web_id=7137738459503199750",
    title: "House",
  },
  {
    // img: inst2,
    img: House9,
    link: "https://www.tiktok.com/@myestatesgroup/video/7117421406182296838?is_copy_url=1&is_from_webapp=v1", //image
    title: "House",
  },
  {
    img: House2,
    link: "https://www.tiktok.com/@myestatesgroup/video/7117421406182296838?is_copy_url=1&is_from_webapp=v1",
    title: "House",
  },
  {
    img: House5, //image
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: gif2,
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: House5, //image
    link: "https://www.tiktok.com/@myestatesgroup/video/7121110661727325445?is_from_webapp=1&sender_device=pc&web_id=7137738459503199750",
    title: "House",
  },
  {
    img: House7,
    link: "https://www.tiktok.com/@myestatesgroup/video/7121110661727325445?is_from_webapp=1&sender_device=pc&web_id=7137738459503199750",
    title: "House",
  },
  {
    img: House12, //image
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: House3,
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: House10, //image
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: House8,
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
  {
    img: House12, //image
    link: "https://vm.tiktok.com/ZMNAsBgtS/",
    title: "House",
  },
];

const facebookImages = [
  {
    id: "1",
    link: "https://fb.watch/d6jzQ4Nlkr/",
    topic: "New listing",
    title: "6772 Dillingwood Dr in Mississauga",
    text: "$1,599,999",
  },
  {
    id: "2",
    link: "https://fb.watch/d6kh1-2Nre/",
    topic: "New listing",
    title: "100 Antibes Dr in Toronto",
    text: "$740,000",
  },
  {
    id: "3",
    link: "https://fb.watch/d6kj53-c_u/",
    topic: "Exciting news",
    title: "We have added new area to our list",
    text: "Miami Central",
  },
  {
    id: "4",
    link: "https://fb.watch/d6jzQ4Nlkr/",
    topic: "New listing",
    title: "6772 Dillingwood Dr in Mississauga",
    text: "$1,599,999",
  },
  {
    id: "5",
    link: "https://fb.watch/d6kh1-2Nre/",
    topic: "New listing",
    title: "100 Antibes Dr in Toronto",
    text: "$740,000",
  },
];

export default class SocialMedia extends Component {
  render() {
    return (
      <div style={{ marginTop: "40px" }}>
        <div className="mainSocialMediaBox">
          <Grid container spacing={2} style={{ paddingTop: "10px" }}>
            <Grid item xs={12} md={4}>
              <a
                href="https://www.instagram.com/myestatesgroup"
                target="_blank"
              >
                <img
                  src={VectorInst}
                  alt="Instagram icon baje"
                  style={{ height: "50px" }}
                />{" "}
              </a>
              <ImageList
                sx={{
                  width: "90%",
                  height: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                cols={2}
                rowHeight={140}
                className="imageScroller"
              >
                {instImages.map((item) => (
                  <a href={item.link} target="_blank">
                    <ImageListItem key={item.img}>
                      <img
                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        // srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                      />
                    </ImageListItem>
                  </a>
                ))}
              </ImageList>
            </Grid>

            <Grid item xs={12} md={4}>
              <a href="https://www.tiktok.com/@myestatesgroup" target="_blank">
                <img
                  src={VectorTikTok}
                  alt="TikTok icon baje"
                  style={{ height: "50px" }}
                />
              </a>
              <ImageList
                sx={{
                  width: "90%",
                  height: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                cols={3}
                rowHeight={164}
                className="imageScroller"
              >
                {tiktokImages.map((item) => (
                  <a href={item.link} target="_blank">
                    <ImageListItem key={item.img}>
                      <img
                        src={`${item.img}?w=164&h=164&fit=crop&auto=format`}
                        srcSet={`${item.img}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`}
                        alt={item.title}
                        loading="lazy"
                        style={{ height: "100%" }}
                      />
                    </ImageListItem>
                  </a>
                ))}
              </ImageList>
            </Grid>

            <Grid item xs={12} md={4}>
              <a
                href="https://www.facebook.com/myestatesgroup/"
                target="_blank"
              >
                <img
                  src={VectorFB}
                  alt="Facebook icon baje"
                  style={{ height: "50px" }}
                />
              </a>

              <ImageList
                sx={{
                  width: "90%",
                  height: 450,
                  marginLeft: "auto",
                  marginRight: "auto",
                }}
                cols={1}
                rowHeight={this.props.windowWidth > 960 ? "120" : "150"}
                className="imageScroller"
              >
                {facebookImages.map((item) => (
                  // <ImageListItem key={item.link}>
                  <ImageListItem key={item.id}>
                    <FacebookPost
                      image={AlexLeraCircle}
                      link={item.link}
                      topic={item.topic}
                      title={item.title}
                      text={item.text}
                    />
                  </ImageListItem>
                ))}
              </ImageList>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }
}
