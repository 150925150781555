import React, { useState, useEffect } from "react";

import "./stylePages/addProperty.css";
import DetailsList from "./extrasForPages/DetailsList";
import FeaturesList from "./extrasForPages/FeaturesList";
import { useNavigate } from "react-router-dom";

// MUI
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import Tooltip from "@mui/material/Tooltip";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import CircularProgress from "@mui/material/CircularProgress";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import axios from "axios";
import { v4 } from "uuid";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../store";

//FIREBASE
import {
  getStorage,
  ref,
  uploadBytesResumable,
  getDownloadURL,
} from "firebase/storage";
import { app } from "../base";

const storage = getStorage(app);

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const useStyles = makeStyles({
  label: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
  select: {
    "&:focus": {
      backgroundColor: "#ffddec",
      color: "red",
    },
    "&:before": {
      borderColor: "red",
    },
    "&:after": {
      borderColor: "red",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    color: "white",
    // '& .MuiOutlinedInput-notchedOutline': {    borderColor: 'white'  }, //changes border color
    "& .MuiOutlinedInput-input": { color: "white" }, // for input color
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border color but doesnt
    // "&:hover .MuiOutlinedInput-input": {      color: "red"    }, //changes  color of input on hover
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border on hover
  },
  icon: {
    fill: "white",
  },
});

const CustomSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "blue",
    },
  },
  checked: {},
  track: {},
})(Switch);

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#F1EDE9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6868AC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F1EDE9",
      },
      "&:hover fieldset": {
        borderColor: "#F1EDE9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6868AC",
      },
    },
  },
})(TextField);

const StyledButton = withStyles({
  root: {
    background:
      "linear-gradient(90deg, #6868AC 0%, rgba(255, 255, 255, 0.5) 100%)",
    color: "#fff",
    border: "solid 1px rgba(255,255,255, 0.25)",
    transitionDuration: "2s",
    "&:hover": {
      background:
        "linear-gradient(90deg, rgba(104, 104, 172, 0.9) 0%, rgba(255, 255, 255, 0.6) 100%)",
      color: "black",
    },
  },
})(Button);

function AddProperty() {
  const navigate = useNavigate();

  const classes = useStyles();

  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const token = useAuthenticatedStore((state) => state.token);
  const setToken = useAuthenticatedStore((state) => state.setToken);

  // useEffect(() => {
  //   console.log("auth is" + authentication);
  //   console.log("is admin: " + userCredentials.isAdmin);
  //   if (authentication !== false || userCredentials.isAdmin !== false) {
  //     navigate("/");
  //   }
  // }, [authentication]);

  if (authentication === false || userCredentials.isAdmin === false) {
    navigate("/");
  }

  let [property, setProperty] = useState({
    name: "", //TextField
    address: "", //TextField
    delivery: "", //TextField - number
    type: "Apartment", //picker from dropdown (Select)
    minBeds: "", //TextField
    maxBeds: "", //TextField
    minBath: "", //TextField
    maxBath: "", //TextField
    minPrice: "", //TextField
    maxPrice: "", //TextField
    minSize: "", //TextField
    maxSize: "", //TextField
    latitude: "", //TextField
    longitude: "", //TextField
    details: ["Live to work style"], //TextField => Array Like TODO List
    features: ["Airbnb friendly"], //TextField => Array Like TODO List
    mainImage: "", //File Picker
    mapImage: "", //File Picker
    floorPlanImage: null, //File Picker
    images: null, //File Picker
    description: "", //TextField
    airbnb: true, // (Switch)
    profitIndex: "", //around 0.083
    status: "Available", //picker from dropdown (Select)
    createdBy: "admin",
  });

  const [detail, setDetail] = useState("");
  const [feature, setFeature] = useState("");
  const [alertOpen, setAlertOpen] = useState(false);
  const [alertOpenProperty, setAlertOpenProperty] = useState(false);
  const [alertErrorOpen, setAlertErrorOpen] = useState(false);
  const [alertErrorOpenMissing, setAlertErrorOpenMissing] = useState(false);
  const [loadingMain, setLoadingMain] = useState(false);
  const [loadingMap, setLoadingMap] = useState(false);
  const [loadingFloorPlan, setLoadingFloorPlan] = useState(false);
  const [loadingImages, setLoadingImages] = useState(false);
  // State to store uploaded file
  const [file, setFile] = useState("");
  const [link, setLink] = useState("");
  const [percent, setPercent] = useState(0);
  const [percentFloorPlan, setPercentFloorPlan] = useState(0);

  const addDetail = (event) => {
    // alert("Hello! Add detail triggered");
    // alert(detail);
    setProperty((prevalue) => {
      return {
        ...prevalue,
        details: [...property.details, detail],
      };
    });

    setDetail("");
  };

  const removeDetail = (id) => {
    // alert("removeDetail button clicked");

    let filtered = [...property.details];
    filtered.splice(id, 1);

    setProperty((prevalue) => {
      return {
        ...prevalue,
        details: filtered,
      };
    });
  };

  const addFeature = (event) => {
    // alert("Hello! Add detail triggered");
    // alert(detail);
    setProperty((prevalue) => {
      return {
        ...prevalue,
        features: [...property.features, feature],
      };
    });

    setFeature("");
  };

  const removeFeature = (id) => {
    // alert("removeDetail button clicked");

    let filtered = [...property.features];
    filtered.splice(id, 1);

    setProperty((prevalue) => {
      return {
        ...prevalue,
        features: filtered,
      };
    });
  };

  const handleKeypress = (e) => {
    //it triggers by pressing the enter key
    if (e.key === "Enter") {
      if (e.target.name === "detail") {
        addDetail();
      }
      if (e.target.name === "feature") {
        addFeature();
      }
    }
  };

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setProperty((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });
  };

  const handleChangePrice = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setProperty((prevalue) => {
      return {
        ...prevalue,
        [name]: value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","),
      };
    });
    console.log(property);
  };

  const handleChangeDetail = (event) => {
    setDetail(event.target.value);
  };
  const handleChangeFeature = (event) => {
    setFeature(event.target.value);
  };
  const handleChangeAirbnb = (event) => {
    setProperty((prevalue) => {
      return {
        ...prevalue,
        ["airbnb"]: !property.airbnb,
      };
    });
  };
  const handleChangeMainImage = (event) => {
    setLoadingMain(true);
    let image = event.target.files[0];
    //send to server
    const formData = new FormData();
    formData.append("mainImage", image, image.name);
    //Axios request
    axios
      .post("/property/images", formData)
      .then((res) => {
        setProperty((prevalue) => {
          return {
            ...prevalue,
            ["mainImage"]: res.data[0],
          };
        });
      })
      .then(() => {
        setAlertOpen(true);
        setLoadingMain(false);
      })
      .catch((err) => {
        setAlertErrorOpen(true);
        console.log(err);
        setLoadingMain(false);
      });
  };

  const handleChangeMapImage = (event) => {
    setLoadingMap(true);
    let image = event.target.files[0];
    //send to server
    const formData = new FormData();
    formData.append("mapImage", image, image.name);
    //Axios request
    axios
      .post("/property/images", formData)
      .then((res) => {
        setProperty((prevalue) => {
          return {
            ...prevalue,
            ["mapImage"]: res.data[0],
          };
        });
      })
      .then(() => {
        setAlertOpen(true);
        setLoadingMap(false);
      })
      .catch((err) => {
        setAlertErrorOpen(true);
        console.log(err);
        setLoadingMap(false);
      });
  };

  const [allFloorPlan, setAllFloorPlan] = useState([]);
  const [floorPlanLinks, setFloorPlanLinks] = useState([]);

  const handleChangeFloorPlanImage = async (event) => {
    setLoadingFloorPlan(true);
    let linksArray = [];
    let folderName = "";
    if (event.target.files && event.target.files.length > 0) {
      //Check if property name exasts to create folder
      if (property.name && property.name.trim !== "") {
        folderName = `FLOOR PLAN ` + property.name;
      } else {
        folderName = `FLOOR PLAN ` + v4();
      }
      for (let i = 0; i < event.target.files.length; i++) {
        let storageRef = ref(
          storage,
          `/${folderName}/${v4() + event.target.files[i].name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          event.target.files[i]
        );
        uploadTask.on(
          "state_changed",
          async (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            setPercentFloorPlan(percent);
          },
          (err) => console.log(err),
          async () => {
            // download url
            let tmpImageLink = await getDownloadURL(
              uploadTask.snapshot.ref
            ).then((url) => {
              linksArray.push(url);
              setFloorPlanLinks((current) => [...current, url]);
              console.log(url);
              setLoadingFloorPlan(false);
            });
          }
        );
      }
    }
    await setProperty((prevalue) => {
      return {
        ...prevalue,
        ["floorPlanImage"]: linksArray,
      };
    });
  };

  const handleChangeFloorPlanImageT = (event) => {
    setLoadingFloorPlan(true);
    let image = event.target.files[0];
    //send to server
    const formData = new FormData();
    formData.append("floorPlanImage", image, image.name);
    //Axios request
    axios
      .post("/property/images", formData)
      .then((res) => {
        setProperty((prevalue) => {
          return {
            ...prevalue,
            ["floorPlanImage"]: res.data[0],
          };
        });
      })
      .then(() => {
        setAlertOpen(true);
        setLoadingFloorPlan(false);
      })
      .catch((err) => {
        setAlertErrorOpen(true);
        console.log(err);
        setLoadingFloorPlan(false);
      });
  };

  const [allImages, setAllImages] = useState([]);
  const [imagesLinks, setImagesLinks] = useState([]);

  const handleChangeImages = async (event) => {
    setLoadingImages(true);
    let linksArray = [];
    let folderName = "";
    if (event.target.files && event.target.files.length > 0) {
      //Check if property name exasts to create folder
      if (property.name && property.name.trim !== "") {
        folderName = property.name;
      } else {
        folderName = v4();
      }
      for (let i = 0; i < event.target.files.length; i++) {
        let storageRef = ref(
          storage,
          `/${folderName}/${v4() + event.target.files[i].name}`
        );
        const uploadTask = uploadBytesResumable(
          storageRef,
          event.target.files[i]
        );
        uploadTask.on(
          "state_changed",
          async (snapshot) => {
            const percent = Math.round(
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            );

            // update progress
            setPercent(percent);
          },
          (err) => console.log(err),
          async () => {
            // download url
            let tmpImageLink = await getDownloadURL(
              uploadTask.snapshot.ref
            ).then((url) => {
              linksArray.push(url);
              setImagesLinks((current) => [...current, url]);
              console.log(url);
              setLoadingImages(false);
            });
          }
        );
      }
    }
    await setProperty((prevalue) => {
      return {
        ...prevalue,
        ["images"]: linksArray,
      };
    });
  };

  //---------------------------------------------------------------------------
  //RESERVE COPY GOOD EXAMPLE

  // const [allImages, setAllImages] = useState([]);
  // const [imagesLinks, setImagesLinks] = useState([]);

  // const handleChangeImages = async (event) => {
  //   setLoadingImages(true);
  //   let linksArray = [];
  //   if (event.target.files && event.target.files.length > 0) {
  //     //check if property name is filled up. Is yes => create folder name of this property name
  //     //if no=> create folder of v4()
  //     for (let i = 0; i < event.target.files.length; i++) {
  //       let storageRef = ref(
  //         storage,
  //         `/files/${v4() + event.target.files[i].name}`
  //       );
  //       const uploadTask = uploadBytesResumable(
  //         storageRef,
  //         event.target.files[i]
  //       );
  //       uploadTask.on(
  //         "state_changed",
  //         async (snapshot) => {
  //           const percent = Math.round(
  //             (snapshot.bytesTransferred / snapshot.totalBytes) * 100
  //           );

  //           // update progress
  //           setPercent(percent);
  //         },
  //         (err) => console.log(err),
  //         async () => {
  //           // download url
  //           let tmpImageLink = await getDownloadURL(
  //             uploadTask.snapshot.ref
  //           ).then((url) => {
  //             linksArray.push(url);
  //             setImagesLinks((current) => [...current, url]);
  //             console.log(url);
  //           });
  //         }
  //       );
  //     }
  //   }
  //   await setProperty((prevalue) => {
  //     return {
  //       ...prevalue,
  //       ["images"]: linksArray,
  //     };
  //   });
  // };

  //--------------------------------------------------------------------------------------------------

  // const handleChangeImages = (event) => {
  //   setLoadingImages(true);
  //   let ins = document.getElementById("images").files.length;
  //   let formData = new FormData();
  //   for (var x = 0; x < ins; x++) {
  //     formData.append("image", document.getElementById("images").files[x]);
  //   }
  //   axios
  //     .post("/property/images", formData)
  //     .then((res) => {
  //       setProperty((prevalue) => {
  //         return {
  //           ...prevalue,
  //           ["images"]: res.data,
  //         };
  //       });
  //     })
  //     .then(() => {
  //       setAlertOpen(true);
  //       setLoadingImages(false);
  //     })
  //     .catch((err) => {
  //       setAlertErrorOpen(true);
  //       console.log(err);
  //       setLoadingImages(false);
  //     });
  // };

  const handleEditMainImage = () => {
    const fileInput = document.getElementById("mainImage");
    fileInput.click();
  };
  const handleEditMapImage = () => {
    const fileInput = document.getElementById("mapImage");
    fileInput.click();
  };
  const handleEditFloorPlanImage = () => {
    const fileInput = document.getElementById("floorPlanImage");
    fileInput.click();
  };
  const handleEditImages = () => {
    const fileInput = document.getElementById("images");
    fileInput.click();
  };

  //playground with axios defaults
  //   axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  axios.defaults.headers.common["Authorization"] = token;

  const handleSubmit = (event) => {
    event.preventDefault();

    let testIfNullArray = Object.values(property);
    let isAnyEmpty = false;

    testIfNullArray.map((item) => {
      if (item === "" || item === null || item === undefined || item === "") {
        isAnyEmpty = true;
      }
    });

    if (isAnyEmpty) {
      setAlertErrorOpenMissing(true);
      return;
    }
    console.log("it kept going");

    //Convert min and max price to string as money amount

    //send axios request
    axios
      .post("/property", property)
      .then((res) => {
        console.log(res.data);
        //clean up all fields
        setProperty({
          name: "",
          address: "",
          delivery: "",
          type: "Apartment",
          minBeds: "",
          maxBeds: "",
          minBath: "",
          maxBath: "",
          minPrice: "",
          maxPrice: "",
          minSize: "",
          maxSize: "",
          latitude: "",
          longitude: "",
          details: ["Live to work style"],
          features: ["Airbnb friendly"],
          mainImage: "",
          mapImage: "",
          floorPlanImage: "",
          images: null,
          description: "",
          airbnb: true,
          profitIndex: "",
          status: "Available",
        });
      })
      .then(() => {
        //send success message
        setAlertOpenProperty(true);
      })
      .catch((err) => {
        setAlertErrorOpen(true);
        console.log(err);
      });
    console.log("axios to add property finished");
  };

  const alertClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpen(false);
  };
  const alertCloseProperty = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertOpenProperty(false);
  };

  const alertErrorClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertErrorOpen(false);
  };

  const alertErrorCloseMissing = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setAlertErrorOpenMissing(false);
  };

  return (
    <div>
      <form noValidate onSubmit={handleSubmit}>
        <div className="addPropertyFields">
          <CssTextField
            id="name"
            name="name"
            type="text"
            label="Property name"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="address"
            name="address"
            type="text"
            label="Property address"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="delivery"
            name="delivery"
            type="number"
            label="Delivery date (when project will be ready) - only year"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <Select
            label="Type"
            onChange={handleChange}
            name="type"
            value={property.type}
            style={{
              marginTop: "24px",
              width: "100%",
              alignSelf: "center",
              marginBottom: "8px",
              textAlign: "left",
            }}
            className={classes.select}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
              },
            }}
          >
            <MenuItem value={"Apartment"}>Apartment</MenuItem>
            <MenuItem value={"House"}>House</MenuItem>
            <MenuItem value={"Other"}>Other</MenuItem>
          </Select>
          <CssTextField
            id="minBeds"
            name="minBeds"
            type="number"
            label="Min Bedrooms"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="maxBeds"
            name="maxBeds"
            type="number"
            label="Max Bedrooms"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="minBath"
            name="minBath"
            type="number"
            label="Min Bathrooms"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="maxBath"
            name="maxBath"
            type="number"
            label="Max Bathrooms"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="minPrice"
            name="minPrice"
            // type="number"
            type="text"
            label="Min Price"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            // value={property.minPrice}
            // onChange={handleChangePrice}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
          <CssTextField
            id="maxPrice"
            name="maxPrice"
            // type="number"
            type="text"
            label="Max Price"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
            InputProps={{
              endAdornment: <InputAdornment position="end">USD</InputAdornment>,
            }}
          />
          <CssTextField
            id="minSize"
            name="minSize"
            type="number"
            label="Min Size"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Sqft</InputAdornment>
              ),
            }}
          />
          <CssTextField
            id="maxSize"
            name="maxSize"
            type="number"
            label="Max Size"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">Sqft</InputAdornment>
              ),
            }}
          />
          <CssTextField
            id="latitude"
            name="latitude"
            type="text"
            label="Latitude"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="longitude"
            name="longitude"
            type="text"
            label="Longitude"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          {/* DETAILS */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
            }}
          >
            <CssTextField
              id="detail"
              name="detail"
              type="text"
              label="Details"
              // className="ContactTextField" //wtf
              variant="outlined"
              onChange={handleChangeDetail}
              margin="normal"
              value={detail}
              InputLabelProps={{ style: { color: "#F1EDE9" } }}
              inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
              style={{ width: "100%" }}
              onKeyDown={handleKeypress}
            />

            <IconButton
              onClick={addDetail}
              sx={{ p: "20px" }}
              aria-label="search"
              style={{ color: "#2cb67d" }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <DetailsList details={property.details} removeDetail={removeDetail} />
          {/* FEATURES */}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              marginTop: "8px",
            }}
          >
            <CssTextField
              id="feature"
              name="feature"
              type="text"
              label="Features"
              // className="ContactTextField" //wtf
              variant="outlined"
              onChange={handleChangeFeature}
              margin="normal"
              value={feature}
              InputLabelProps={{ style: { color: "#F1EDE9" } }}
              inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
              style={{ width: "100%" }}
              onKeyDown={handleKeypress}
            />

            <IconButton
              onClick={addFeature}
              sx={{ p: "20px" }}
              aria-label="search"
              style={{ color: "#2cb67d" }}
            >
              <AddIcon />
            </IconButton>
          </div>
          <FeaturesList
            features={property.features}
            removeFeature={removeFeature}
          />
          <h2 className="addMainImageHeader">Main image</h2>
          <input
            type="file"
            id="mainImage"
            onChange={handleChangeMainImage}
            hidden="hidden"
          />
          {property.mainImage ? (
            <Tooltip title="Add main image" placement="top">
              <IconButton
                onClick={handleEditMainImage}
                className="buttonEditMainImage"
              >
                <EditIcon style={{ color: "#7f5af0" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit main image" placement="top">
              <IconButton
                onClick={handleEditMainImage}
                className="buttonEditMainImage"
              >
                <AttachFileIcon style={{ color: "#2cb67d" }} />
              </IconButton>
            </Tooltip>
          )}
          {property.mainImage ? (
            <div>
              <img src={property.mainImage} className="mainImagePreview"></img>
            </div>
          ) : (
            loadingMain && (
              <div>
                <CircularProgress size={50} />
              </div>
            )
          )}
          <h2 className="addMainImageHeader">Map image</h2>
          <input
            type="file"
            id="mapImage"
            onChange={handleChangeMapImage}
            hidden="hidden"
          />
          {property.mapImage ? (
            <Tooltip title="Add map image" placement="top">
              <IconButton
                onClick={handleEditMapImage}
                className="buttonEditMapImage"
              >
                <EditIcon style={{ color: "#7f5af0" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit map image" placement="top">
              <IconButton
                onClick={handleEditMapImage}
                className="buttonEditMapImage"
              >
                <AttachFileIcon style={{ color: "#2cb67d" }} />
              </IconButton>
            </Tooltip>
          )}
          {property.mapImage ? (
            <div>
              <img src={property.mapImage} className="mapImagePreview"></img>
            </div>
          ) : (
            loadingMap && (
              <div>
                <CircularProgress size={50} />
              </div>
            )
          )}
          {/* FLOOR PLANS START */}
          <h2 className="addMainImageHeader">Floor plan image</h2>
          <p>{percentFloorPlan} % done</p> {/* Add percentage for floorplan */}
          <input
            type="file"
            id="floorPlanImage"
            onChange={handleChangeFloorPlanImage} //redo that function
            hidden="hidden"
            name="allfloorplans"
            accept="/image/*"
            multiple
          />
          {property.floorPlanImage ? (
            <Tooltip title="Add floor plan image" placement="top">
              <IconButton
                onClick={handleEditFloorPlanImage} //redo that function
                className="buttonEditFloorPlanImage"
              >
                <EditIcon style={{ color: "#7f5af0" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit floor plan image" placement="top">
              <IconButton
                onClick={handleEditFloorPlanImage} //redo that function
                className="buttonEditFloorPlanImage"
              >
                <AttachFileIcon style={{ color: "#2cb67d" }} />
              </IconButton>
            </Tooltip>
          )}
          {/* {property.floorPlanImage ? (
            <div>
              <img
                src={property.floorPlanImage}
                className="floorPlanImagePreview"
              ></img>
            </div>
          ) : (
            loadingFloorPlan && (
              <div>
                <CircularProgress size={50} />
              </div>
            )
          )} */}
          {property.floorPlanImage ? (
            <div>
              {property.floorPlanImage.map((image, index) => (
                <img src={image} key={index} className="imagesPreview" />
              ))}
            </div>
          ) : (
            loadingFloorPlan && (
              <div>
                <CircularProgress size={50} />
              </div>
            )
          )}
          {/* FLOOR PLANS END */}
          {/* ALL IMAGES START */}
          <h2 className="addAllImagesHeader">All property images</h2>
          <p>{percent} % done</p>
          <input
            type="file"
            id="images"
            onChange={handleChangeImages}
            hidden="hidden"
            name="allimages"
            accept="/image/*"
            multiple
          />
          {property.images ? (
            <Tooltip title="Add all property images" placement="top">
              <IconButton
                onClick={handleEditImages}
                className="buttonEditImages"
              >
                <EditIcon style={{ color: "#7f5af0" }} />
              </IconButton>
            </Tooltip>
          ) : (
            <Tooltip title="Edit property images" placement="top">
              <IconButton
                onClick={handleEditImages}
                className="buttonEditImages"
              >
                <AttachFileIcon style={{ color: "#2cb67d" }} />
              </IconButton>
            </Tooltip>
          )}
          {property.images ? (
            <div>
              {property.images.map((image, index) => (
                <img src={image} key={index} className="imagesPreview" />
              ))}
            </div>
          ) : (
            loadingImages && (
              <div>
                <CircularProgress size={50} />
              </div>
            )
          )}
          <CssTextField
            id="description"
            name="description"
            type="text"
            label="Description"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            multiline
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <CssTextField
            id="profitIndex"
            name="profitIndex"
            type="number"
            label="Profit index"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />
          <Select
            label="status"
            onChange={handleChange}
            name="status"
            value={property.status}
            style={{
              marginTop: "24px",
              width: "100%",
              alignSelf: "center",
              marginBottom: "8px",
              textAlign: "left",
            }}
            className={classes.select}
            inputProps={{
              classes: {
                icon: classes.icon,
                root: classes.root,
              },
            }}
          >
            <MenuItem value={"Available"}>Available</MenuItem>
            <MenuItem value={"Sold"}>Sold</MenuItem>
          </Select>
          <FormControlLabel
            control={
              <CustomSwitch
                checked={property.airbnb}
                name="airbnb"
                onChange={handleChangeAirbnb}
                size="large"
                style={{ color: "blue" }}
              />
            }
            label="Airbnb friendly"
          />
          {/* <Button
              className='submitButton'
              style={{color: 'green', fontSize: '16px', display: 'block', margin: 'auto'}}
              onClick={handleSubmit}
            >Save</Button> */}
          <button className="submitButton" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </form>
      <div style={{ height: "50vh" }} /> {/* to delete */}
      <Snackbar
        open={alertOpenProperty}
        autoHideDuration={6000}
        onClose={alertCloseProperty}
      >
        <Alert
          onClose={alertCloseProperty}
          severity="success"
          sx={{ width: "100%" }}
        >
          Property created successfully
        </Alert>
      </Snackbar>
      <Snackbar open={alertOpen} autoHideDuration={6000} onClose={alertClose}>
        <Alert onClose={alertClose} severity="success" sx={{ width: "100%" }}>
          Added successfully
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertErrorOpen}
        autoHideDuration={6000}
        onClose={alertErrorClose}
      >
        <Alert
          onClose={alertErrorClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error! Please, call +1 (226) 224 - 1256 for assistance
        </Alert>
      </Snackbar>
      <Snackbar
        open={alertErrorOpenMissing}
        autoHideDuration={6000}
        onClose={alertErrorCloseMissing}
      >
        <Alert
          onClose={alertErrorCloseMissing}
          severity="warning"
          sx={{ width: "100%" }}
        >
          One or more fields are empty
        </Alert>
      </Snackbar>
    </div>
  );
}

export default AddProperty;
