import React, { Component } from 'react'

import MiamiConstruction1 from '../images/MiamiConstruction1.png';
import '../styles/freeConsultation.css';
import { Link } from 'react-router-dom';

// MUI
import { Button } from '@material-ui/core';

class FreeConsultation extends Component {
  render() {
    return (
      <div style={{marginTop: '-200px'}}>

          {/* header */}

            <h4 className='FreeConsultationHeader1'>GET A</h4>
            <h4 className='FreeConsultationHeader2'>FREE</h4>
            <Button className='FreeConsultationButton' component={Link} to="/contactus" >
                <p className='FreeConsultationText'>
                  CONSULTATION
                </p>
            </Button>
          
        <div
        className='FreeConsultationImg'
        style={{
            backgroundImage: `url(${MiamiConstruction1})`
        }}
        >
            
        </div> 

      </div>
    )
  }
}

export default FreeConsultation;