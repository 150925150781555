import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import "../stylePages/propertyCard.css";
import MyButton from "../../util/MyButton";

// MUI
import Button from "@mui/material/Button";
import Dialog from "@material-ui/core/Dialog";
import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress } from "@mui/material";
import { Grid, withStyles } from "@material-ui/core";
import Switch from "@mui/material/Switch";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../../store";

const CustomSwitch = withStyles({
  switchBase: {
    color: "green",
    "&$checked": {
      color: "red",
    },
    "&$checked + $track": {
      backgroundColor: "blue",
    },
  },
  checked: {},
  track: {},
})(Switch);

function PropertyCard(props) {
  const data = props.data;
  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const token = useAuthenticatedStore((state) => state.token);

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [available, setAvailable] = useState(
    data.status === "Available" ? true : false
  );

  const handleChangeAvailability = (event) => {
    axios.defaults.headers.common["Authorization"] = token;

    axios
      .post(`/propertyStatus/${data.propertyId}`, { status: !available })
      .then(() => {
        alert(`Property ${data.propertyId} updated successfully`);
      })
      .catch((err) => {
        alert(`Error: ${err}`);
      });
    setAvailable(event.target.checked);
  };

  const deleteProperty = () => {
    axios.defaults.headers.common["Authorization"] = token;
    setLoading(true);
    // alert("Kinda deleted");
    axios
      .delete(`/property/${data.propertyId}`)
      .then(() => {
        alert(`Property ${data.propertyId} deleted successfully`);
        setLoading(false);
        handleClose();
      })
      .catch((err) => {
        console.log(err);
        setLoading(false);
      });
  };

  const dialogToOpenState = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div
      className="propertyCardMain"
      style={{ background: open === true ? "#e53170" : "" }}
    >
      <Dialog onClose={handleClose} open={open} style={{}}>
        <h4 className="deletePropertyWarning">
          Are you sure you want to delete {data.type} at {data.address}{" "}
        </h4>
        {loading && (
          <CircularProgress
            style={{
              color: "#2cb67d",
              position: "absolute",
              left: "50%",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}
          />
        )}
        <Grid container spacing={0} style={{ marginBottom: "40px" }}>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              onClick={handleClose}
              className="propertyFilterButton"
              style={{ color: "brown", fontSize: "16px" }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid
            item
            xs={6}
            style={{ display: "flex", justifyContent: "center" }}
          >
            <Button
              className="propertyFilterButton"
              style={{ color: "green", fontSize: "16px" }}
              onClick={() => deleteProperty()}
            >
              Delete
            </Button>
          </Grid>
        </Grid>
      </Dialog>
      <Link to={props.data.propertyId}>
        <img
          src={data.mainImage}
          alt="Image of the house"
          className="propertyCardMainImage"
        />
      </Link>
      <h4 className="propertyCardName">
        ${data.minPrice.split(".")[0]} - ${data.maxPrice.split(".")[0]}
      </h4>
      <p className="propertyCardPrice">
        {data.name} {data.type}
      </p>
      <p className="propertyCardDetails" style={{ marginTop: "16px" }}>
        Delivery: {data.delivery}
      </p>
      <p className="propertyCardDetails">
        Beds: {data.minBeds} - {data.maxBeds}
      </p>
      <p className="propertyCardDetails">
        Bath: {data.minBath} - {data.maxBath}
      </p>
      <p className="propertyCardDetails">
        Size: {data.minSize} - {data.maxSize} SqFt
      </p>
      {authentication && userCredentials.isAdmin ? (
        <div
          style={{
            display: "block",
            marginLeft: "0px",
            marginRight: "auto",
            marginTop: "4px",
            marginBottom: "-10px",
            textAlign: "left",
          }}
        >
          <CustomSwitch
            checked={available}
            onChange={handleChangeAvailability}
            size="large"
            style={{ color: "blue" }}
          />
        </div>
      ) : (
        <></>
      )}
      <div
        style={{
          height: "1px",
          width: "100%",
          background: "rgba(0, 0, 0, 0.25)",
          marginTop: "16px",
        }}
      />
      <div className="propertyCardBottomMain">
        <p
          className="propertyCardBottomData"
          style={{
            alignSelf: "flex-start",
            marginLeft: "16px",
            color: data.status === "Sold" ? "#e53170" : "#2cb67d",
          }}
        >
          {data.status}
        </p>
        <div style={{ marginTop: "8px" }}>
          {authentication && userCredentials.isAdmin ? (
            <div
              style={{
                marginRight: "8px",
                display: "inline-block",
              }}
            >
              <MyButton tip="Delete property" onClick={dialogToOpenState}>
                <ClearIcon style={{ color: "black" }} />
              </MyButton>
            </div>
          ) : (
            <></>
          )}
          <Button
            className="propertyCardBottomData"
            component={Link}
            to={props.data.propertyId}
            // style={{alignSelf: 'flex-end', marginRight: '16px'}}
            style={{ marginRight: "16px", textDecoration: "underline" }}
          >
            Details
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PropertyCard;
