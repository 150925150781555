import React from 'react'
import Detail from './Detail'

function DetailsList({details, removeDetail}) {
  return (
    <div>
        {details.map((detail, index) => {
            return (
                <Detail key={index} detail={detail} removeDetail={removeDetail} index={index}/>
            )
        })}
    </div>
  )
}

export default DetailsList;