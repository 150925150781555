import React, { Component, useRef, useState } from "react";
import { useSpring, animated, config } from "@react-spring/web";
import "./reasonToBuy.css";

import AOS from "aos";

const calc = (x, y, rect) => [
  -(y - rect.top - rect.height / 2) / 5,
  (x - rect.left - rect.width / 2) / 5,
  1.4,
];
const trans = (x, y, s) =>
  `perspective(600px) rotateX(${x}deg) rotateY(${y}deg) scale(${s})`;

export default function ReasonToBuy(parameters) {
  const configList = Object.keys(config);
  const ref = useRef(null);
  const [xys, set] = useState([0, 0, 1]);
  // const { preset } = useControls({
  //   preset: { value: "default", options: configList }
  // });
  const preset = { value: "default", options: configList };
  const props = useSpring({ xys, config: config[preset] });

  return (
    <div>
      <div
        className="ReasonToBuy"
        style={{
          marginTop: parameters.marginTop,
          marginLeft: parameters.marginLeft,
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <animated.div
          ref={ref}
          className="ccard"
          style={{
            transform: props.xys.to(trans),
            // marginTop: "40px",
          }}
          onMouseLeave={() => set([0, 0, 1])}
          onMouseMove={(e) => {
            const rect = ref.current.getBoundingClientRect();
            set(calc(e.clientX, e.clientY, rect));
          }}
        >
          <p style={{}}>{parameters.text}</p>
        </animated.div>
      </div>
    </div>
  );
}

// import React, { Component } from "react";

// class ReasonToBuy extends Component {
//   render() {
//     return (
//       <div
//         className="ReasonToBuy"
//         style={{
//           marginTop: this.props.marginTop,
//           marginLeft: this.props.marginLeft,
//           textAlign: "center",
//           display: "flex",
//           justifyContent: "center",
//           alignItems: "center",
//         }}
//       >
//         <p style={{}}>{this.props.text}</p>
//       </div>
//     );
//   }
// }

// export default ReasonToBuy;
