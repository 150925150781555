import React, { Component } from "react";
// import "./App.css";
import "../../../node_modules/react-vis/dist/style.css";
import {
  XYPlot,
  LineSeries,
  VerticalGridLines,
  HorizontalGridLines,
  YAxis,
  XAxis,
} from "react-vis";

class Chart extends Component {
  render() {
    const data = [
      { x: 1993, y: 277 },
      { x: 1994, y: 280 },
      { x: 1995, y: 312 },
      { x: 1996, y: 425 },
      { x: 1997, y: 423 },
      { x: 1998, y: 420 },
      { x: 1999, y: 455 },
      { x: 2000, y: 460 },
      { x: 2001, y: 535 },
      { x: 2002, y: 526 },
      { x: 2003, y: 530 },
      { x: 2004, y: 515 },
      { x: 2005, y: 615 },
      { x: 2006, y: 740 },
      { x: 2007, y: 809 },
      { x: 2008, y: 713 },
      { x: 2009, y: 555 },
      { x: 2010, y: 550 },
      { x: 2011, y: 587 },
      { x: 2012, y: 666 },
      { x: 2013, y: 775 },
      { x: 2014, y: 867 },
      { x: 2015, y: 940 },
      { x: 2016, y: 860 },
      { x: 2017, y: 913 },
    ];
    return (
      <div style={{ display: "inline-block" }}>
        <h4 style={{ fontWeight: "400" }}>Average closed sales price</h4>
        <XYPlot height={300} width={300}>
          {/* <VerticalGridLines /> */}
          {/* <HorizontalGridLines /> */}
          <XAxis />
          <YAxis
            tickFormat={(v) => v + "k"}
            // tickValues={[200, 300, 400, 500, 600, 700, 800, 900, 1000]}
          />
          {/* <XAxis tickValues={[2008, 2010, 2012, 2014, 2016]} />
          <YAxis tickFormat={(v) => v * v} /> */}
          <LineSeries data={data} color="#2cb67d" />
        </XYPlot>
      </div>
    );
  }
}

export default Chart;

// import * as React from "react";
// import Paper from "@mui/material/Paper";
// import {
//   Chart,
//   ArgumentAxis,
//   ValueAxis,
//   BarSeries,
//   SplineSeries,
//   Legend,
// } from "@devexpress/dx-react-chart-material-ui";
// import { ValueScale, Animation } from "@devexpress/dx-react-chart";

// // import { sales as data } from "../../../demo-data/data-vizualization";

// export default class Demo extends React.PureComponent {
//   constructor(props) {
//     super(props);
//     const data = {
//       2017: [
//         { month: "Jan", sale: 50, total: 987 },
//         { month: "Feb", sale: 100, total: 3000 },
//         { month: "Mar", sale: 30, total: 1100 },
//         { month: "Apr", sale: 107, total: 7100 },
//         { month: "May", sale: 95, total: 4300 },
//         { month: "Jun", sale: 150, total: 7500 },
//         { month: "Jul", sale: 120, total: 5300 },
//         { month: "Aug", sale: 110, total: 2500 },
//         { month: "Sep", sale: 54, total: 2300 },
//         { month: "Oct", sale: 129, total: 2600 },
//         { month: "Nov", sale: 48, total: 3400 },
//         { month: "Dec", sale: 43, total: 3200 },
//       ],
//     };
//     this.state = {
//       data: data[2017],
//     };
//   }

//   render() {
//     const { data: chartData } = this.state;

//     return (
//       <Paper>
//         <Chart data={chartData}>
//           <ValueScale name="sale" />
//           <ValueScale name="total" />

//           <ArgumentAxis />
//           <ValueAxis scaleName="sale" showGrid={false} showLine showTicks />
//           <ValueAxis
//             scaleName="total"
//             position="right"
//             showGrid={false}
//             showLine
//             showTicks
//           />

//           <BarSeries
//             name="Units Sold"
//             valueField="sale"
//             argumentField="month"
//             scaleName="sale"
//           />

//           <SplineSeries
//             name="Total Transactions"
//             valueField="total"
//             argumentField="month"
//             scaleName="total"
//           />
//           <Animation />
//           {/* <Legend /> */}
//         </Chart>
//       </Paper>
//     );
//   }
// }

// {
//   /* <Paper>
//         <Chart data={chartData}>
//           <ValueScale name="sale" />
//           <ValueScale name="total" />

//           <ArgumentAxis />
//           <ValueAxis scaleName="sale" showGrid={false} showLine showTicks />
//           <ValueAxis
//             scaleName="total"
//             position="right"
//             showGrid={false}
//             showLine
//             showTicks
//           />

//           <BarSeries
//             name="Units Sold"
//             valueField="sale"
//             argumentField="month"
//             scaleName="sale"
//           />

//           <SplineSeries
//             name="Total Transactions"
//             valueField="total"
//             argumentField="month"
//             scaleName="total"
//           />
//           <Animation />
//           <Legend />
//         </Chart>
//       </Paper> */
// }
