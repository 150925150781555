import { width } from "@mui/system";
import React, { Component } from "react";

import AOS from "aos";

export default class Step extends Component {
  render() {
    let windowWidth = this.props.windowWidth;

    let styleForMainDiv = {
      position: "relative",
      display: "flex",
      alignItems: "center",
      // marginLeft: '24px',
      // margin:'20%',
      marginLeft: "20%",
      marginBottom: "50px",
      marginTop: "24px",
    };

    let styleForCircleDiv = {
      width: "50px",
      height: "50px",
      background: "rgba(104, 104, 172, 0.5)",
      filter: "blur(10px)",
      // marginLeft: 'auto',
      // marginRight: 'auto',
      zIndex: "3",
      // display: 'inline-block',
      // position: 'absolute',
    };

    let styleForTextDiv = {
      dispaly: "inline-block",
    };

    let styleForCountNumberDiv = {
      zIndex: "10",
      position: "absolute",
      marginLeft: "22px",
    };

    let headerTextStyle = {
      display: "flex",
      fontWeight: "500",
      fontSize: "20px",
      margin: "0",
      marginLeft: "40px",
    };

    let paragraphTextStyle = {
      // display: "inline-block",
      textAlign: "left",
      justifyContent: "start",
      fontWeight: "400",
      fontSize: "16px",
      margin: "0",
      marginTop: "8px",
      marginLeft: "40px",
      opacity: "90%",
    };

    if (this.props.windowWidth > 1900) {
      styleForMainDiv = {
        position: "relative",
        display: "flex",
        marginLeft: "36%",
        marginBottom: "40px",
        marginTop: "40px",
      };
      styleForCircleDiv = {
        position: "absolute",
        width: "50px",
        height: "50px",
        background: "rgba(104, 104, 172, 0.5)",
        filter: "blur(10px)",
        marginTop: "16px",
        zIndex: "3",
        // display: 'inline-block',
        // position: 'absolute',
      };
      styleForTextDiv = {
        dispaly: "inline-block",
        marginLeft: "35px",
        maxWidth: "85%",
      };
      styleForCountNumberDiv = {
        zIndex: "10",
        position: "absolute",
        marginLeft: "22px",
        top: "12px",
      };
      headerTextStyle = {
        display: "flex",
        fontWeight: "500",
        fontSize: "32px",
        margin: "0",
        marginLeft: "40px",
      };
      paragraphTextStyle = {
        // display: "inline-block",
        textAlign: "left",
        justifyContent: "start",
        fontWeight: "400",
        fontSize: "22px",
        margin: "0",
        marginTop: "8px",
        marginLeft: "40px",
        opacity: "90%",
      };
    } else if (this.props.windowWidth > 898) {
      styleForMainDiv = {
        position: "relative",
        display: "flex",
        alignItems: "center",
        // marginLeft: '24px',
        // margin:'20%',
        marginLeft: "20%",
        marginBottom: "50px",
        marginTop: "24px",
      };
      styleForCircleDiv = {
        width: "50px",
        height: "50px",
        background: "rgba(104, 104, 172, 0.5)",
        filter: "blur(10px)",
        // marginLeft: 'auto',
        // marginRight: 'auto',
        zIndex: "3",
        // display: 'inline-block',
        // position: 'absolute',
      };
      styleForTextDiv = {
        dispaly: "inline-block",
      };
      styleForCountNumberDiv = {
        zIndex: "10",
        position: "absolute",
        marginLeft: "22px",
        // top: '0px',
      };
      headerTextStyle = {
        display: "flex",
        fontWeight: "500",
        fontSize: "20px",
        margin: "0",
        marginLeft: "40px",
      };
      paragraphTextStyle = {
        // display: "inline-block",
        textAlign: "left",
        justifyContent: "start",
        fontWeight: "400",
        fontSize: "16px",
        margin: "0",
        marginTop: "8px",
        marginLeft: "40px",
        opacity: "90%",
      };
    } else if (this.props.windowWidth < 700) {
      styleForMainDiv = {
        position: "relative",
        display: "flex",
        marginLeft: "8%",
        marginBottom: "40px",
        marginTop: "40px",
      };
      styleForCircleDiv = {
        position: "absolute",
        width: "50px",
        height: "50px",
        background: "rgba(104, 104, 172, 0.5)",
        filter: "blur(10px)",
        marginTop: "16px",
        zIndex: "3",
        // display: 'inline-block',
        // position: 'absolute',
      };
      styleForTextDiv = {
        dispaly: "inline-block",
        marginLeft: "35px",
        maxWidth: "85%",
      };
      styleForCountNumberDiv = {
        zIndex: "10",
        position: "absolute",
        marginLeft: "22px",
        // top: '12px',
      };
      headerTextStyle = {
        display: "flex",
        fontWeight: "500",
        fontSize: "20px",
        margin: "0",
        marginLeft: "40px",
      };
      paragraphTextStyle = {
        // display: "inline-block",
        textAlign: "left",
        justifyContent: "start",
        fontWeight: "400",
        fontSize: "16px",
        margin: "0",
        marginTop: "8px",
        marginLeft: "40px",
        opacity: "90%",
      };
    }

    return (
      <div style={styleForMainDiv}>
        <div style={styleForCircleDiv}></div>
        <p style={styleForCountNumberDiv} className="styleForCountNumberDiv">
          {this.props.countNumber}
        </p>
        <div style={styleForTextDiv}>
          <h6
            data-aos={windowWidth > 960 ? "fade-right" : ""}
            data-aos-anchor-placement={windowWidth > 960 ? "top-bottom" : ""}
            // style={headerTextStyle}
            className="stepsHeaderTextStyle"
          >
            {this.props.headerText}
          </h6>
          <p
            data-aos={windowWidth > 960 ? "fade-right" : ""}
            data-aos-anchor-placement={windowWidth > 960 ? "top-bottom" : ""}
            // style={paragraphTextStyle}
            className="stepsParagraphTextStyle"
          >
            {this.props.text}
          </p>
        </div>
      </div>
    );
  }
}
