import React, { Component } from "react";

// MUI
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";
import { Grid } from "@material-ui/core";

// Images
import Alex from "../images/Alex.png";
import Lera from "../images/Lera.png";
import Polly from "../images/Polly.png";

import AOS from "aos";

class Team extends Component {
  constructor(props) {
    super(props);
    this.state = {
      openLera: false,
      openAlex: false,
      openAltagracia: false,
    };
  }

  handleClose = (e) => {
    this.setState({
      openLera: false,
      openAlex: false,
      openAltagracia: false,
    });
  };

  handleOpenLera = (e) => {
    this.setState({
      openLera: true,
    });
  };

  handleOpenAlex = (e) => {
    this.setState({
      openAlex: true,
    });
  };

  handleOpenAltagracia = (e) => {
    this.setState({
      openAltagracia: true,
    });
  };

  render() {
    let windowWidth = this.props.windowWidth;

    return (
      <div>
        <h2
          className="teamHeader"
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
        >
          MY ESTATES TEAM
        </h2>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} sm={6}>
            <Button
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenLera}
            >
              <div
                className="PersonDiv"
                data-aos="zoom-in-right"
                data-aos-anchor-placement="center-bottom"
              >
                {/* <div style={{backgroundColor: 'red', height: '100px', width: '100%', margin: 'auto', position: 'absolute'}}></div> */}
                <img
                  src={Lera}
                  alt={"Valeria Khomovych"}
                  className="TeamMemberImage"
                  style={{}}
                />
                <h4 className="TeamMemberName">Valeria Khomovych</h4>
                <h5 className="TeamMemberStatus">Team Leader</h5>
                <h6 className="TeamMemberNumber">+1 (647) 779 - 2794</h6>
                <h6 className="TeamMemberEmail">valeria@myestatesgroup.com</h6>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <Button
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenAlex}
            >
              <div
                className="PersonDiv"
                data-aos="zoom-in-up"
                data-aos-anchor-placement="center-bottom"
              >
                <img
                  src={Alex}
                  alt={"Oleksandr Lichevetskyy"}
                  className="TeamMemberImage"
                  style={{}}
                />
                <h4 className="TeamMemberName">Oleksandr Lichevetskyy</h4>
                <h5 className="TeamMemberStatus">Project manager</h5>
                <h6 className="TeamMemberNumber">+1 (647) 287 - 7555</h6>
                <h6 className="TeamMemberEmail">
                  oleksandr@myestatesgroup.com
                </h6>
              </div>
            </Button>
          </Grid>
          <Grid item xs={12} md={4} sm={6}>
            <Button
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenAltagracia}
            >
              <div
                className="PersonDiv"
                // data-aos="fade-right"
                // data-aos={windowWidth > 960 ? "fade-left" : "fade-right"}
                data-aos="zoom-in-left"
                data-aos-anchor-placement="center-bottom"
              >
                <img
                  src={Polly}
                  alt={"Polly De Windt"}
                  className="TeamMemberImage"
                  style={{ filter: "brightness(83%)" }}
                />
                <h4 className="TeamMemberName">Polly De Windt</h4>
                <h5 className="TeamMemberStatus">
                  Licensed realtor for Cervera Real Estate
                </h5>
                <h6 className="TeamMemberNumber">+1 (202) 330 - 1367</h6>
                <h6 className="TeamMemberEmail">apdewindt@aol.com</h6>
              </div>
            </Button>
          </Grid>
        </Grid>

        <Dialog
          onClose={this.handleClose}
          open={this.state.openLera}
          className="TeamDialog"
          PaperProps={{
            style: {
              background: "rgba(100, 100, 100, 0.5)",
              width: "100%",
              height: windowWidth > 900 ? "60%" : "85vh",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              //   boxShadow: 'none',
            },
          }}
          style={{
            background: "rgba(0,0,0,.6)",
          }}
        >
          {/* Make a grid for everything */}
          <div className="PersonDialog">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <img
                  src={Lera}
                  alt={"Valeria Khomovych"}
                  className="TeamMemberImageDialog"
                  style={{
                    margin: "0",
                    marginTop: windowWidth > 900 ? "0px" : "24px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <h4
                  className="TeamMemberNameDialog"
                  style={{
                    marginTop: windowWidth > 900 ? "20%" : "0px",
                  }}
                >
                  Valeria Khomovych
                </h4>
                <h5 className="TeamMemberStatusDialog">Team Leader</h5>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    {windowWidth < 898 ? (
                      <a href="tel:+16477792794">
                        <h6 className="TeamMemberNumberDialog">
                          +1 (647) 779 - 2794
                        </h6>
                      </a>
                    ) : (
                      <h6 className="TeamMemberNumberDialog">
                        +1 (647) 779 - 2794
                      </h6>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <a href="mailto:valeria@myestatesgroup.com">
                      <h6 className="TeamMemberEmailDialog">
                        valeria@myestatesgroup.com
                      </h6>
                    </a>
                  </Grid>
                </Grid>

                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginTop: "24px" }}
                >
                  Has access to exclusive VIP properties
                </h6>
                <h6 className="TeamMemberQualityDialog">Early Bird</h6>
                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginBottom: "16px" }}
                >
                  Namaste to generational wealth
                </h6>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          onClose={this.handleClose}
          open={this.state.openAlex}
          className="TeamDialog"
          PaperProps={{
            style: {
              background: "rgba(100, 100, 100, 0.5)",
              width: "100%",
              height: windowWidth > 900 ? "60%" : "85vh",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              //   boxShadow: 'none',
            },
          }}
          style={{
            background: "rgba(0,0,0,.6)",
          }}
        >
          {/* Make a grid for everything */}
          <div className="PersonDialog">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <img
                  src={Alex}
                  alt={"Oleksandr Lichevetskyy"}
                  className="TeamMemberImageDialog"
                  style={{
                    margin: "0",
                    marginTop: windowWidth > 900 ? "0px" : "24px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <h4
                  className="TeamMemberNameDialog"
                  style={{
                    marginTop: windowWidth > 900 ? "20%" : "0px",
                  }}
                >
                  Oleksandr Lichevetskyy
                </h4>
                <h5 className="TeamMemberStatusDialog">Project manager</h5>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    {windowWidth < 898 ? (
                      <a href="tel:+16472877555">
                        <h6 className="TeamMemberNumberDialog">
                          +1 (647) 287 - 7555
                        </h6>
                      </a>
                    ) : (
                      <h6 className="TeamMemberNumberDialog">
                        +1 (647) 287 - 7555
                      </h6>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <a href="mailto:oleksandr@myestatesgroup.com">
                      <h6 className="TeamMemberEmailDialog">
                        oleksandr@myestatesgroup.com
                      </h6>
                    </a>
                  </Grid>
                </Grid>

                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginTop: "24px" }}
                >
                  Best $ for more
                </h6>
                <h6 className="TeamMemberQualityDialog">
                  Straight to the point
                </h6>
                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginBottom: "16px" }}
                >
                  Every deal is a slam dunk
                </h6>
              </Grid>
            </Grid>
          </div>
        </Dialog>

        <Dialog
          onClose={this.handleClose}
          open={this.state.openAltagracia}
          className="TeamDialog"
          PaperProps={{
            style: {
              background: "rgba(100, 100, 100, 0.5)",
              width: "100%",
              height: windowWidth > 900 ? "60%" : "85vh",
              backdropFilter: "blur(5px)",
              WebkitBackdropFilter: "blur(5px)",
              //   boxShadow: 'none',
            },
          }}
          style={{
            background: "rgba(0,0,0,.6)",
          }}
        >
          {/* Make a grid for everything */}
          <div className="PersonDialog">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <img
                  src={Polly}
                  alt={"Altagracia"}
                  className="TeamMemberImageDialog"
                  style={{
                    margin: "0",
                    marginTop: windowWidth > 900 ? "0px" : "24px",
                  }}
                />
              </Grid>
              <Grid item xs={12} md={8}>
                <h4
                  className="TeamMemberNameDialog"
                  style={{
                    marginTop: windowWidth > 900 ? "20%" : "0px",
                  }}
                >
                  Polly De Windt
                </h4>
                <h5 className="TeamMemberStatusDialog">
                  Miami new Construction Specialist
                </h5>
                <Grid container>
                  <Grid item xs={12} md={6}>
                    {windowWidth < 898 ? (
                      <a href="tel:+12023301367">
                        <h6 className="TeamMemberNumberDialog">
                          +1 (202) 330 - 1367
                        </h6>
                      </a>
                    ) : (
                      <h6 className="TeamMemberNumberDialog">
                        +1 (202) 330 - 1367
                      </h6>
                    )}
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <a href="mailto:apdewindt@aol.com">
                      <h6 className="TeamMemberEmailDialog">
                        apdewindt@aol.com
                      </h6>
                    </a>
                  </Grid>
                </Grid>

                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginTop: "24px" }}
                >
                  Clients first
                </h6>
                <h6 className="TeamMemberQualityDialog">
                  I'll dance after we close the deal
                </h6>
                <h6
                  className="TeamMemberQualityDialog"
                  style={{ marginBottom: "16px" }}
                >
                  In-House Sales Executive
                </h6>
              </Grid>
            </Grid>
          </div>
        </Dialog>
      </div>
    );
  }
}

export default Team;
