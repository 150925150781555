import React from 'react'

import IconButton from '@mui/material/IconButton';
import ClearIcon from '@mui/icons-material/Clear';

export default function Feature({ feature, removeFeature, index }) {

    function handleRemoveFeature(){
        removeFeature(index);
    }

    // console.log(index)
  return (
    <div>
        {feature}
        <IconButton 
            onClick={handleRemoveFeature}
            sx={{ p: '20px' }} aria-label="search" style={{color: '#e53170'}}>
            <ClearIcon />
        </IconButton>
    </div>
  )
}
