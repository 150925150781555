// import firebase from "firebase";
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getDatabase } from "firebase/database";

const firebaseConfig = {
  apiKey: "AIzaSyBKK9LfW4uAwGNH29Zhe3ZU97jXDoqr0sY",
  authDomain: "myestatesgroupinc.firebaseapp.com",
  projectId: "myestatesgroupinc",
  storageBucket: "myestatesgroupinc.appspot.com",
  messagingSenderId: "642825586778",
  appId: "1:642825586778:web:cc4502843ad0ff5dfaf063",
  measurementId: "G-4T7P0JPN4K",
};

export const app = initializeApp(firebaseConfig);
// export const firebaseAuth = getAuth(app);
// export const fbDatabase = getDatabase(app);
// export const fStore = getFirestore(app);
// export const fStorage = getStorage();
