import React from "react";

export default function terms() {
  return (
    <div>
      <h1 style={{ fontWeight: "400" }}>Terms & Conditions</h1>
      <h3 style={{ fontWeight: "300" }}>For Residents of New York</h3>
      <p className="termsParagraph">
        Important Message for Residents of New York: Nothing contained in this
        website is intended or shall be deemed to be an offer to sell real
        estate or real estate securities to residents of the state of New York.
        In furtherance of the foregoing, Insert Name of Brokerage. (“Sponsor“)
        hereby discloses the following: (a) neither Sponsor, nor its
        Principal(s) taking part in any public offering or sale are incorporated
        in, located in, or resident in the state of New York, (b) any offering
        is neither made in the state of New York nor made to the residents of
        the state of New York, (c) any offering is not directed to any person or
        entity in the state of New York by, or on behalf of, Sponsor or anyone
        acting with Sponsor’s knowledge; and (d) no offering or purchase or sale
        of a security or any unit shall take place as a result of any offering
        in New York or with a resident of the state of New York, until all
        registration and filing requirements under the Martin Act and the New
        York Attorney General’s regulations are complied with; a written
        exemption is obtained pursuant to an application is granted pursuant to
        and in accordance with cooperative policy statements #1 or #7; or a
        “no-action” request is granted.
      </p>
      <p className="termsParagraph">
        This Site may provide links to websites which are not affiliated with
        the Sponsor.  Your access to those sites are governed by the terms of
        use therein, and not by these Terms.{" "}
      </p>
      <p className="termsParagraph">
        Please read these Terms and Conditions carefully. By accessing, browsing
        or using this website and any other websites operated by Insert Name of
        Brokerage (the “Site”), you accept and acknowledge your assent to,
        without limitation or qualification, the Terms and Conditions. If you do
        not agree to the Terms and Conditions, then please do not access, browse
        or use the Site.
      </p>
      <h3 style={{ fontWeight: "300" }}>1.  Copyright &amp; Trademarks:</h3>
      <p className="termsParagraph">
        All content, designs, text, graphics, button icons, page headers,
        service names, the selection and arrangement thereof, and all code and
        software on and in the Site is protected by copyrights, trademarks,
        service marks and/or other intellectual property rights (which are
        governed, by U.S. and worldwide copyright laws and treaty provisions,
        privacy and publicity laws, and communication regulations and
        statutes).  Unless otherwise noted herein or on the Site, all content on
        the Site is owned by Insert Name of Brokerage, (the “Company“). All
        rights reserved. You should assume that the underlying code and
        everything you see or read on the Site (collectively, the “Information”)
        is copyrighted unless otherwise noted. The Information may not be used
        other than in accordance with the Terms and Conditions without the prior
        written consent of the Company. The redistribution, retransmission,
        republication, sale or commercial exploitation of the Information is
        expressly prohibited without the prior written consent of the Company,
        except that you may view and download one copy of the Information to one
        computer for your own personal, non-commercial use only, provided that
        you do not delete or change any copyright, trademark, or other
        proprietary notices. The Company neither warrants nor represents that
        your use of the Information will not infringe upon the rights of third
        parties not owned by or affiliated with the Company.
      </p>
      <p className="termsParagraph">
        The trademarks, logos, and service marks (collectively, the
        “Trademarks“) displayed on the Site are registered and unregistered
        Trademarks of the Company and others. Nothing contained on the Site
        should be construed as granting, by implication, estoppel or otherwise,
        any license or right to use any Trademark displayed on the Site without
        the prior written consent of the Company or such third party that may
        own the Trademarks displayed on the Site. The Trademarks displayed on
        the Site may not be used other than in accordance with the Terms and
        Conditions.
      </p>
      <p className="termsParagraph">
        The Company has the right to terminate or restrict your access to the
        Site, unilaterally and without notice, for any reason, including but not
        limited to in the event you violate any of these Terms.  You are also
        advised that the Company will aggressively enforce its intellectual
        property rights to the fullest extent of the law, including seeking
        criminal prosecution.
      </p>
      <h3 style={{ fontWeight: "300" }}>2.  Information you provide:</h3>
      <p className="termsParagraph">
        Any and all information, content, leads, images, documentation, data,
        questions, comments, or suggestions provided by you to this Site shall
        be deemed to be non-confidential and non-proprietary.  The Company shall
        have no obligation, or limitation, with respect to its use of such
        information, and shall be free to reproduce, use, disclose, and/or
        distribute such information without limitation and without remuneration
        to you.
      </p>
      <h3 style={{ fontWeight: "300" }}>3.  No warranty:</h3>
      <p className="termsParagraph">
        All information, content, and services made available through the Site
        is for informational purposes only and is provided by the Company on an
        “as is” basis, without warranties of any kind, either express or
        implied. Without limiting the generality of the forgoing, the Company,
        its owners, affiliates, officers, directors, employees, agents,
        contractors, content or service providers, successors and assigns,
        specifically disclaim any and all warranties, express or implied by
        statute or otherwise, including but not limited to: (1) any warranties
        concerning the availability, accuracy, usefulness, or content of or
        uninterrupted access to information, products or services and (2) any
        warranties of title, merchantability or fitness for a particular
        purpose.
      </p>
      <p className="termsParagraph">
        Any listing on the Site may be updated, changed, modified, withdrawn,
        sold or rented at any time without notice. Unless otherwise noted,
        furnishings, decorations and artist representations are provided for
        illustrative purposes only.  All square footage and dimensions are
        approximate.  Before you act on any information you have found on the
        Site, you agree to independently confirm any facts that are important to
        your decision.  While the Company believes all the information on the
        Site to be correct, the information contained on the Site is not
        intended to be comprehensive, and may in fact not be accurate,
        up-to-date or applicable to any particular case.  If you rely on any
        information available through the Site, you expressly agree that you do
        so at your own risk and that you are solely responsible for any damage
        or loss that may result therefrom.
      </p>
      <h3 style={{ fontWeight: "300" }}>4.  Disclaimer of Liability:</h3>
      <p className="termsParagraph">
        Your access to, browsing of or use of the Site is at your own risk.
        Neither the Company nor its respective owners, shareholders, directors,
        officers, employees, agents, contractors, representatives or affiliates
        or any other party involved in creating, producing, or delivering the
        site, content or the information shall be liable for, including without
        limitation, (A) indirect, punitive, incidental or consequential damages,
        (B) loss of or damage to data or computer equipment, (C) loss of or
        damage to income or profits, (D) loss of or damage to property or (E)
        claims of any parties, whether based in law or in equity or on statute,
        contract, tort, strict liability or otherwise, for any reason arising
        out of or in connection with the access to, browsing of or use of the
        Site, the information or any site hyperlinked hereto including without
        limitation, any failure or performance, error, omission, inaccuracy,
        interruption, defect, delay in operation of transmission, virus or other
        harmful component, or line of failure.
      </p>
      <h3 style={{ fontWeight: "300" }}>5. Off-Site Links:</h3>
      <p className="termsParagraph">
        The third-party sites hyperlinked to or from the Site are not under the
        control of the Company. The Company has not reviewed any or all of the
        third-party sites hyperlinked to or from the Site. The Company does not
        make any representations regarding, and is not responsible for, the
        content or accuracy of any third-party sites hyperlinked to or from the
        Site. The products and services which can be accessed, purchased or
        obtained through such third-party sites are from persons or entities
        other than the Company. The Company does not make any representations or
        warranties of any kind, including warranty of merchantability or
        warranty of fitness for a particular purpose, with regard to such
        services and products. If you chose to hyperlink to or from any
        third-party site hyperlinked to or from the Site, then doing so shall be
        at your own risk.
      </p>
      <h3 style={{ fontWeight: "300" }}>6.  Prohibited Jurisdictions:</h3>
      <p className="termsParagraph">
        The Company does not authorize the downloading or exportation of the
        Information or any software or technical data from the Site to any
        jurisdiction which prohibits the downloading or exportation of such
        Information or data or to any jurisdiction prohibited by United States
        export control laws.
      </p>
      <h3 style={{ fontWeight: "300" }}>
        7.  Choice of Law, Jurisdiction, and Venue:
      </h3>
      <p className="termsParagraph">
        The Site, the Information, the Terms and Conditions, any suit, action or
        proceeding arising out of, or with respect to, any of the foregoing, or
        any judgment entered by any court in respect thereof, shall be governed
        by and construed in accordance with the laws of the State of Florida
        without regard to choice of law rules. Any suit, action or proceeding
        arising out of, or with respect to, the Site, the Information or the
        Terms and Conditions, or any judgment entered by any court in respect
        thereof, shall be brought exclusively in the courts of Miami-Dade
        County, Florida or in the United States District Court for the Southern
        District of Florida situated in Miami, Florida. All parties hereby
        consent to personal jurisdiction in the State of Florida, acknowledge
        that venue is proper in the courts of Miami-Dade County, Florida or in
        the United States District Court for the Southern District of Florida
        situated in Miami, Florida, and waive any objections that may exist, now
        or in the future, with respect to any of the foregoing.
      </p>
      <h3 style={{ fontWeight: "300" }}>8.  Amendments</h3>
      <p className="termsParagraph">
        The Company may at any time and without notice revise the Terms and
        Conditions by updating this posting. You are bound by any such revisions
        and should therefore periodically visit this page to review the then
        current Terms and Conditions to which you are bound. You should also
        know that the Company continuously revises the Information.
      </p>
      <h3 style={{ fontWeight: "300" }}>9.  Severability:</h3>
      <p className="termsParagraph">
        In the event that any one or more of the provisions contained in the
        Terms and Conditions shall be declared invalid, void or unenforceable,
        the remaining provisions of the Terms and Conditions shall remain in
        full force and effect, and such invalid, void or unenforceable provision
        shall be interpreted as closely as possible to the manner in which it
        was written.
      </p>
    </div>
  );
}
