import React, { Component } from "react";
// import { GoogleMap, LoadScript } from '@react-google-maps/api';
import {
  withGoogleMap,
  GoogleMap,
  InfoWindow,
  Marker,
  LoadScript,
} from "@react-google-maps/api";
import { Link } from "react-router-dom";
import "./stylePages/map.css";

// MUI
import { Button } from "@mui/material";
import { Helmet } from "react-helmet";

const containerStyle = {
  width: "100%",
  height: "100vh",
  marginTop: "-80px",
  color: "black",
};

// let propertiesArray = props.data.houses;

// const markers = propertiesArray.map(function(property, i){
//   return {id: i, name: property.name, position: { lat: property.latitude, lng: property.longitude}}
// })

// const markers = propertiesArray.map(function(property, i){
//   return {id: i, name: property.name, position: { lat: property.latitude, lng: property.longitude}}
// })
var aarkers = [];
// {propertiesArray ? (
//   propertiesArray.map((property, i) =>
//   markers.push( {id: i, name: property.name, position: { lat: parseFloat(property.latitude), lng: parseFloat(property.longitude)}, mainImage: property.images[0]} )
// )
// ) : (<p>Loading...</p>)}
// propertiesArray.map((property, i) =>
//   markers.push( {id: i, name: property.name, position: { lat: parseFloat(property.latitude), lng: parseFloat(property.longitude)}, mainImage: property.images[0]} )
// )

// console.log("Markers is: " + markers[10].position.lng);
// // console.log("Markers is: " + markers.length);

const darkers = [
  {
    id: 1,
    name: "Miami 265 Brooklin St.",
    position: { lat: 25.795374153933047, lng: -80.20062224063801 },
  },
  {
    id: 2,
    name: "Miami Lower St. - $895,000",
    position: { lat: 25.79073738360572, lng: -80.22602812319157 },
  },
  {
    id: 3,
    name: "Chicago, Illinois",
    position: { lat: 41.881832, lng: -87.623177 },
  },
  {
    id: 4,
    name: "Denver, Colorado",
    position: { lat: 39.739235, lng: -104.99025 },
  },
  {
    id: 5,
    name: "Los Angeles, California",
    position: { lat: 34.052235, lng: -118.243683 },
  },
  {
    id: 6,
    name: "New York, New York",
    position: { lat: 40.712776, lng: -74.005974 },
  },
];

const denter = {
  lat: parseFloat(darkers[0].position.lat),
  lng: parseFloat(darkers[0].position.lng),
};

// const center = {
//   lat: 25.795374153933047,
//   lng: -80.20062224063801
// }

class MyComponents extends Component {
  constructor() {
    super();
    this.state = {
      activeMarker: "",
    };
  }

  render() {
    var markers = [];
    let propertiesArray = this.props.data;

    {
      propertiesArray ? (
        propertiesArray.map((property, i) =>
          markers.push({
            id: i,
            propertyId: property.propertyId,
            name: property.name,
            position: {
              lat: parseFloat(property.latitude),
              lng: parseFloat(property.longitude),
            },
            mainImage: property.mainImage,
          })
        )
      ) : (
        <p>Loading...</p>
      );
    }
    console.log(propertiesArray);

    let center = {};
    center = {
      lat: 25.88587,
      lng: -80.281511,
    };
    // const center = {
    //   lat: parseFloat(markers[0].position.lat),
    //   lng: parseFloat(markers[0].position.lng)
    // };

    const handleActiveMarker = (marker) => {
      if (marker === this.state.activeMarker) {
        return;
      }
      this.setState({
        activeMarker: marker,
      });
    };

    console.log(process.env);

    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Estates Group - Map</title>
        </Helmet>
        {propertiesArray ? (
          <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_API_KEY}>
            <GoogleMap
              mapContainerStyle={containerStyle}
              center={center}
              zoom={10}
            >
              {markers.map(({ id, name, position, mainImage, propertyId }) => (
                <Marker
                  key={id}
                  position={position}
                  onClick={() => handleActiveMarker(id)}
                  // style={{color: 'black'}}
                >
                  {this.state.activeMarker === id ? (
                    <InfoWindow
                      onCloseClick={() => this.setState({ activeMarker: null })}
                    >
                      <div>
                        <div className="mapPropertyName">{name}</div>
                        {/* <Button component={Link} to={id}> <img src={mainImage} alt={name} className='mapMainImageProperty'/></Button> */}
                        <Button
                          component={Link}
                          to={`/properties/${propertyId}`}
                          onClick={() => console.log(propertyId)}
                        >
                          <img
                            src={mainImage}
                            alt={name}
                            className="mapMainImageProperty"
                          />
                        </Button>
                        <a
                          href={`https://www.google.com/maps/search/?api=1&query=${position.lat}%2C${position.lng}`}
                          target="_blank"
                          className="mapViewOnMapBtn"
                        >
                          View on Google Maps
                        </a>
                      </div>
                    </InfoWindow>
                  ) : null}
                </Marker>
              ))}
              {/* Child components, such as markers, info windows, etc. */}
              <></>
            </GoogleMap>
          </LoadScript>
        ) : (
          <p>Loading...</p>
        )}
      </div>
    );
  }
}

export default MyComponents;

//   <LoadScript
//   googleMapsApiKey="AIzaSyBllVrzdwfbD-NdG84Kt2nPc6hJKTcyJOE"
//   >
//   <GoogleMap
//     mapContainerStyle={containerStyle}
//     center={center}
//     zoom={10}
//   >

//     {markers.map(({ id, name, position, mainImage, propertyId }) => (
//       <Marker
//         key={id}
//         position={position}
//         onClick={() => handleActiveMarker(id)}
//         // style={{color: 'black'}}
//       >
//         {this.state.activeMarker === id ? (
//           <InfoWindow onCloseClick={() => this.setState({activeMarker: null})}>
//             <div>
//               <div className='mapPropertyName'>{name}</div>
//               {/* <Button component={Link} to={id}> <img src={mainImage} alt={name} className='mapMainImageProperty'/></Button> */}
//               <Button component={Link} to={`/properties/${propertyId}`} onClick={() => console.log(propertyId)}><img src={mainImage} alt={name} className='mapMainImageProperty'/></Button>
//               <a href={`https://www.google.com/maps/search/?api=1&query=${position.lat}%2C${position.lng}`} target="_blank" className='mapViewOnMapBtn'>View on Google Maps</a>
//             </div>
//           </InfoWindow>
//         ) : null}
//       </Marker>
//     ))}
//     { /* Child components, such as markers, info windows, etc. */ }
//     <></>
//   </GoogleMap>
// </LoadScript>
