import React, { useRef, useState, useEffect } from "react";
import "./stylePages/login.css";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// MUI
import { withStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";

import axios from "axios";

import { Helmet } from "react-helmet";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../store";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#F1EDE9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6868AC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F1EDE9",
      },
      "&:hover fieldset": {
        borderColor: "#F1EDE9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6868AC",
      },
    },
  },
})(TextField);

const useStyles = (theme) => ({
  label: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
  select: {
    "&:focus": {
      backgroundColor: "#ffddec",
      color: "red",
    },
    "&:before": {
      borderColor: "red",
    },
    "&:after": {
      borderColor: "red",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    color: "white",
    // '& .MuiOutlinedInput-notchedOutline': {    borderColor: 'white'  }, //changes border color
    "& .MuiOutlinedInput-input": { color: "white" }, // for input color
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border color but doesnt
    // "&:hover .MuiOutlinedInput-input": {      color: "red"    }, //changes  color of input on hover
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border on hover
  },
  icon: {
    fill: "white",
  },
});

function Login(props) {
  const navigate = useNavigate();

  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const setUserCredentials = useCredentialsStore(
    (state) => state.setUserCredentials
  );
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const setAuthentication = useAuthenticatedStore(
    (state) => state.setAuthenticated
  );
  const token = useAuthenticatedStore((state) => state.token);
  const setToken = useAuthenticatedStore((state) => state.setToken);

  useEffect(() => {
    if (authentication === true) {
      navigate("/");
    }
  }, [authentication]);

  let [userData, setUserData] = useState({
    email: "", //textField
    password: "", //TF
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setUserData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });

    //remove error from specific field
    setErrors((prevalue) => {
      return {
        ...prevalue,
        [name]: undefined,
      };
    });
  };

  const handleSubmit = (event) => {
    // alert('hi');
    event.preventDefault();
    setLoading(true);
    axios
      .post("/login", userData)
      .then((res) => {
        console.log(res.data);
        //STORE TOKEN (RES.DATA) IN LOCAL STORAGE / Zustand
        localStorage.setItem("FBIdToken", `Bearer ${res.data.token}`);
        setToken(`Bearer ${res.data.token}`);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        setAuthentication(true);
        getUserData();
        setLoading(false);
        navigate("/"); //Redirect
      })
      .catch((err) => {
        setErrors(err.response.data);
        setLoading(false);
      });
  };

  const getUserData = () => {
    // alert("getting user details");
    axios
      .get("/getAuthenticatedUser")
      .then((res) => {
        setUserCredentials(res.data.credentials);
      })
      .catch((err) => {
        setErrors(err.response.data);
        console.log(err);
      });
  };

  console.log(userData + loading);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Estates Group - Login</title>
      </Helmet>
      <h1 className="loginMainName">Login</h1>

      <form noValidate onSubmit={handleSubmit}>
        <div className="addPropertyFields">
          <CssTextField
            id="email"
            name="email"
            type="text"
            label="Email"
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            helperText={errors.email}
            error={errors.email ? true : false}
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />

          <CssTextField
            id="password"
            name="password"
            type="email"
            label="Password"
            // className="ContactTextField" //wtf
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            helperText={errors.password}
            error={errors.password ? true : false}
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />

          <p style={{ color: "#e53170" }}>{errors.general}</p>

          {loading === true ? (
            <CircularProgress style={{ color: "#2cb67d" }} />
          ) : (
            <div></div>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="DoneButton"
            size="medium"
            disabled={loading}
            // style={{fontFamily: 'Jost'}}
          >
            <div className="contactUsButtonSubmitText">Login</div>
          </Button>

          <Link to="/signup">
            <p className="noAccountLogin">
              Don't have an account? Register here
            </p>
          </Link>
        </div>
      </form>
    </div>
  );
}

export default Login;

// import React, { useRef } from 'react';
// import { useNumberStore, useCredentialsStore } from '../store';

// function Login() {
//   const inputRef = useRef();
//   const inputRef2 = useRef();
//   const numberRef = useRef();
//   const userCredentials = useCredentialsStore(state => state.userCredentials);
//   const setUserCredentials = useCredentialsStore(state => state.setUserCredentials);

//   const number = useNumberStore((state) => state.number);
//   const setNumber = useNumberStore((state) => state.setNumber);

//   const add = () => {
//     setUserCredentials(inputRef.current.value, inputRef2.current.value);
//   }

//   return (
//     <div>
//       <p>login</p>
//       <div>{userCredentials.email}</div>
//       <div>{userCredentials.fullName}</div>
//       <div>{number}</div>
//       <div><input type="text" onChange={(e) => setNumber(e.target.value)}></input></div>
//       <div><input type="text" ref={inputRef} /></div>
//       <div><input type="text" ref={inputRef2} /></div>
//       <div><button onClick={add}>Change Email</button></div>
//     </div>
//   )
// }

// export default Login;
