import React, { useRef, useState, useEffect } from "react";
import "./stylePages/login.css";
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

// MUI
import { withStyles } from "@material-ui/core/styles";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";
import { TextField } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import DoneIcon from "@mui/icons-material/Done";
import IconButton from "@mui/material/IconButton";

import axios from "axios";

import { Helmet } from "react-helmet";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../store";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#F1EDE9",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#6868AC",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "#F1EDE9",
      },
      "&:hover fieldset": {
        borderColor: "#F1EDE9",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#6868AC",
      },
    },
  },
})(TextField);

//SELECT
const useStyles = makeStyles({
  label: {
    color: "white",
    "&.Mui-focused": {
      color: "white",
    },
  },
  select: {
    "&:focus": {
      backgroundColor: "#ffddec",
      color: "red",
    },
    "&:before": {
      borderColor: "red",
    },
    "&:after": {
      borderColor: "red",
    },
    "& .MuiSvgIcon-root": {
      color: "white",
    },
    color: "white",
    // '& .MuiOutlinedInput-notchedOutline': {    borderColor: 'white'  }, //changes border color
    "& .MuiOutlinedInput-input": { color: "white" }, // for input color
    "& .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border color but doesnt
    // "&:hover .MuiOutlinedInput-input": {      color: "red"    }, //changes  color of input on hover
    "&:hover .MuiOutlinedInput-notchedOutline": { borderColor: "white" }, //should change border on hover
  },
  icon: {
    fill: "white",
  },
});

function Signup(props) {
  const navigate = useNavigate();

  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const setUserCredentials = useCredentialsStore(
    (state) => state.setUserCredentials
  );
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const setAuthentication = useAuthenticatedStore(
    (state) => state.setAuthenticated
  );
  const token = useAuthenticatedStore((state) => state.token);
  const setToken = useAuthenticatedStore((state) => state.setToken);

  useEffect(() => {
    if (authentication === true) {
      navigate("/");
    }
  }, [authentication]);
  // const classes = useStyles(); //CHANGE
  const classes = useStyles();
  const windowWidth = window.screen.availWidth;

  let [userData, setUserData] = useState({
    fullName: "", //textField
    email: "", //textField
    phoneNumber: "", //TF
    priceRange: "500.000$ - 1.000.000$", //Select
    timeRange: "12-24 month", //Select
    heardFrom: "Instagram", //Select
    comment: "", //TF
    password: "", //TF
    confirmPassword: "", //TF
  });

  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const handleChange = (event) => {
    let value = event.target.value;
    let name = event.target.name;

    setUserData((prevalue) => {
      return {
        ...prevalue,
        [name]: value,
      };
    });

    //remove error from specific field
    setErrors((prevalue) => {
      return {
        ...prevalue,
        [name]: undefined,
      };
    });
  };

  const handleSubmit = (event) => {
    // alert('hi');
    event.preventDefault();
    setLoading(true);
    axios
      .post("/signup", userData)
      .then((res) => {
        console.log(res.data);
        //STORE TOKEN (RES.DATA) IN LOCAL STORAGE / Zustand
        localStorage.setItem("FBIdToken", `Bearer ${res.data.token}`);
        setToken(`Bearer ${res.data.token}`);
        axios.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${res.data.token}`;
        setAuthentication(true);
        getUserData();
        setLoading(false);
        navigate("/"); //Redirect
      })
      .catch((err) => {
        setErrors(err.response.data);
        setLoading(false);
      });
  };

  const getUserData = () => {
    // alert("getting user details");
    axios
      .get("/getAuthenticatedUser")
      .then((res) => {
        setUserCredentials(res.data.credentials);
      })
      .catch((err) => {
        setErrors(err.response.data);
        console.log(err);
      });
  };

  console.log(userData + loading);
  console.log(props.windowWidth);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Estates Group - Sign up</title>
      </Helmet>
      <h1 className="loginMainName">Register</h1>

      <form noValidate onSubmit={handleSubmit}>
        <div className="addPropertyFields">
          <Grid container>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingRight: windowWidth > 600 ? "16px" : "0px" }}
            >
              <CssTextField
                id="fullName"
                name="fullName"
                type="text"
                label="Name"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                helperText={errors.fullName}
                error={errors.fullName ? true : false}
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px", width: "100%" }}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingLeft: windowWidth > 600 ? "16px" : "0px" }}
            >
              <CssTextField
                id="email"
                name="email"
                type="text"
                label="Email"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                helperText={errors.email}
                error={errors.email ? true : false}
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px", width: "100%" }}
              />
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingRight: windowWidth > 600 ? "16px" : "0px" }}
            >
              <CssTextField
                id="phoneNumber"
                name="phoneNumber"
                type="tel"
                label="+1 (111) 111 - 1111"
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                helperText={errors.phoneNumber}
                error={errors.phoneNumber ? true : false}
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px", width: "100%" }}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingLeft: windowWidth > 600 ? "16px" : "0px" }}
            >
              <Select
                label="How did you find out about us?"
                onChange={handleChange}
                name="heardFrom"
                value={userData.heardFrom}
                style={{
                  marginTop: "24px",
                  width: "100%",
                  alignSelf: "center",
                  marginBottom: "8px",
                  textAlign: "left",
                }}
                className={classes.select}
                error={errors.heardFrom}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                <MenuItem className="GetPreApprovalMenuItem" value={"TikTok"}>
                  TikTok
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Facebook"}>
                  Facebook
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"Instagram"}
                >
                  Instagram
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Google"}>
                  Google
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Friend"}>
                  Friend
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Random"}>
                  Random
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={`I won't tell you`}
                >
                  I won't tell you
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Other"}>
                  Other
                </MenuItem>
              </Select>
            </Grid>
          </Grid>

          <Grid container>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingRight: windowWidth > 600 ? "16px" : "0px" }}
            >
              <Select
                label="Price range"
                onChange={handleChange}
                name="priceRange"
                value={userData.priceRange}
                style={{
                  marginTop: "24px",
                  width: "100%",
                  alignSelf: "center",
                  marginBottom: "8px",
                  textAlign: "left",
                }}
                className={classes.select}
                error={errors.priceRange}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"Less than 500.000$"}
                >
                  Less than 500.000$
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"500.000$ - 1.000.000$"}
                >
                  500.000$ - 1.000.000$
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"1.000.000$ - 5.000.000$"}
                >
                  1.000.000$ - 5.000.000$
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"More than 5.000.000$"}
                >
                  More than 5.000.000$
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Other"}>
                  Other
                </MenuItem>
              </Select>
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingLeft: windowWidth > 600 ? "16px" : "0px" }}
            >
              <Select
                label="Time range"
                onChange={handleChange}
                name="timeRange"
                value={userData.timeRange}
                style={{
                  marginTop: "24px",
                  width: "100%",
                  alignSelf: "center",
                  marginBottom: "8px",
                  textAlign: "left",
                }}
                className={classes.select}
                error={errors.timeRange}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                    root: classes.root,
                  },
                }}
              >
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"Within a year"}
                >
                  Within a year
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"12-24 month"}
                >
                  12 - 24 month
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"2-5 years"}
                >
                  2 - 5 years
                </MenuItem>
                <MenuItem
                  className="GetPreApprovalMenuItem"
                  value={"More than 5 years"}
                >
                  More than 5 years
                </MenuItem>
                <MenuItem className="GetPreApprovalMenuItem" value={"Other"}>
                  Other
                </MenuItem>
              </Select>
            </Grid>
          </Grid>

          <CssTextField
            id="comment"
            name="comment"
            type="text"
            label="Comment"
            variant="outlined"
            onChange={handleChange}
            margin="normal"
            multiline
            helperText={errors.comment}
            error={errors.comment ? true : false}
            InputLabelProps={{ style: { color: "#F1EDE9" } }}
            inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
            style={{ marginTop: "24px", width: "100%" }}
          />

          <Grid container>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingRight: windowWidth > 600 ? "16px" : "0px" }}
            >
              <CssTextField
                id="password"
                name="password"
                type="email"
                label="Password"
                // className="ContactTextField" //wtf
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                helperText={errors.password}
                error={errors.password ? true : false}
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px", width: "100%" }}
              />
            </Grid>
            <Grid
              item
              sm={6}
              xs={12}
              style={{ paddingLeft: windowWidth > 600 ? "16px" : "0px" }}
            >
              <CssTextField
                id="confirmPassword"
                name="confirmPassword"
                type="email"
                label="Confirm password"
                // className="ContactTextField" //wtf
                variant="outlined"
                onChange={handleChange}
                margin="normal"
                helperText={errors.confirmPassword}
                error={errors.confirmPassword ? true : false}
                InputLabelProps={{ style: { color: "#F1EDE9" } }}
                inputProps={{ style: { fontFamily: "Jost", color: "#F1EDE9" } }}
                style={{ marginTop: "24px", width: "100%" }}
              />
            </Grid>
          </Grid>

          <p style={{ color: "#e53170" }}>{errors.general}</p>

          {loading === true ? (
            <CircularProgress style={{ color: "#2cb67d" }} />
          ) : (
            <div></div>
          )}

          <Button
            type="submit"
            variant="contained"
            color="primary"
            className="DoneButton"
            size="medium"
            disabled={loading}
            style={{ marginTop: windowWidth > 600 ? "0px" : "32px" }}
          >
            {/* <div className='contactUsButtonSubmitText'>Register</div> */}
            <Tooltip title="Signup" placement="top">
              {/* <DoneIcon style={{color: '#2cb67d'}} /> */}
              <DoneIcon fontSize="large" style={{ width: "100px" }} />
            </Tooltip>
          </Button>

          <Link to="/login">
            <p className="noAccountLogin">
              Already have an account? Login here
            </p>
          </Link>

          <div style={{ height: "10vh" }} />
        </div>
      </form>
    </div>
  );
}

export default Signup;
