//Password from Nika's nout is Vladimir1010
import React, { useEffect, useState } from "react";
import axios from "axios";
import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
// import { useLocation } from "react-router-dom";
import jwtDecode from "jwt-decode";

import { useAuthenticatedStore, useCredentialsStore } from "./store";

//Swiper
import "swiper/css/bundle";
import "./styles.css";

//MUI
import { ThemeProvider, createTheme } from "@mui/material/styles";

//Pages
import Home from "./pages/home";
import Map from "./pages/map";
import Properties from "./pages/properties";
import ContactUs from "./pages/contactUs";
import Property from "./pages/property";
import Login from "./pages/login";
import Signup from "./pages/signup";
import AddProperty from "./pages/addProperty";
import Error from "./pages/error";
import SecretPage from "./pages/secretPage";
import Terms from "./pages/terms";
// import Test from "./pages/test";

//Helper
import ScrollToTop from "./pages/extrasForPages/ScrollToTop";
// import AuthRoute from "./util/AuthRoute";

//Components
import Navbar from "./components/Navbar";
// import { createMuiTheme } from "@mui/material";

// AOS
import AOS from "aos";
import "aos/dist/aos.css";

axios.defaults.baseURL =
  "https://us-central1-myestatesgroupinc.cloudfunctions.net/api";

const theme = createTheme({
  palette: {
    primary: {
      // main: red[500],
      main: "#F1EDE9",
    },
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
});

let authenticated = false;
const token = localStorage.FBIdToken;
if (token) {
  const decodedToken = jwtDecode(token);
  // console.log(decodedToken);
  if (decodedToken.exp * 1000 < Date.now()) {
    //REDIRECT TO LOGIN PAGE
    // window.location.href = '/login'
    authenticated = false;
  } else {
    authenticated = true;
  }
}
console.log(`Auth is ${authenticated}`);

function App() {
  const windowWidth = window.screen.availWidth;

  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const setAuthentication = useAuthenticatedStore(
    (state) => state.setAuthenticated
  );
  // const token = useAuthenticatedStore((state) => state.token); //If uncomment - it stops grabing
  //value from func and reassigns to empty
  const setToken = useAuthenticatedStore((state) => state.setToken);

  const [data, setData] = useState([]); // UNCOMMENT FOR USING API DATA
  useEffect(() => {
    axios
      .get("/properties")
      .then((res) => {
        console.log(res.data);
        setData(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    console.log(authenticated);
    setAuthentication(authenticated);
    setToken(token);
    console.log(authenticated);
  }, []);

  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <Router>
          <Navbar windowWidth={windowWidth} />
          <ScrollToTop />
          <div className="container">
            <Routes>
              <Route
                exact
                path="/"
                element={<Home windowWidth={windowWidth} />}
              />
              <Route exact path="/map" element={<Map data={data} />} />
              <Route path="/properties" element={<Properties data={data} />} />
              <Route exact path="/contactus" element={<ContactUs />} />
              <Route
                exact
                path="/properties/:propertyId"
                element={<Property data={data} />}
              />
              <Route exact path="/login" element={<Login />} />
              <Route
                exact
                path="/signup"
                element={<Signup windowWidth={windowWidth} />}
              />
              <Route exact path="/addproperty" element={<AddProperty />} />
              <Route exact path="/secretPage" element={<SecretPage />} />
              <Route exact path="/terms" element={<Terms />} />
              {/* <Route exact path="/test" element={<Test />} /> */}
              <Route path="/404" element={<Error />} />
              <Route path="*" element={<Error />} />
            </Routes>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
