import React, { Component } from "react";
import Footer from "../components/Footer";

import { InlineWidget } from "react-calendly";

// Images
import Alex from "../images/Alex.png";
import Lera from "../images/Lera.png";

// MUI
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";

import { Helmet } from "react-helmet";

class ContactUs extends Component {
  render() {
    return (
      <div>
        <Helmet>
          <meta charSet="utf-8" />
          <title>My Estates Group - Contact us</title>
        </Helmet>
        <h2 className="contactUsPageHeader">Contact Us</h2>
        <Grid container spacing={0}>
          <Grid item xs={12} md={4} sm={6} style={{ marginBottom: "24px" }}>
            <div
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenLera}
            >
              <div className="PersonDiv">
                <img
                  src={Lera}
                  alt={"Valeria Khomovych"}
                  className="TeamMemberImage"
                  style={{}}
                />
                <h4 className="TeamMemberName">Valeria Khomovych</h4>
                <h5 className="TeamMemberStatus">
                  TeamLead & Sales Representative
                </h5>
                {/* <h6 className='TeamMemberNumber'>+1 (647) 779 - 2794</h6> */}
                <a href="tel:+16477792794">
                  <h6 className="TeamMemberNumber">+1 (647) 779 - 2794</h6>
                </a>
                {/* <h6 className='TeamMemberEmail'>valeriaKhomovych@gmail.com</h6> */}
                <a href="mailto:valeria@myestatesgroup.com">
                  <h6 className="TeamMemberEmail">
                    valeria@myestatesgroup.com
                  </h6>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={6} style={{ marginBottom: "24px" }}>
            <div
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenAlex}
            >
              <div className="PersonDiv">
                <img
                  src={Alex}
                  alt={"Oleksandr Lichevetskyy"}
                  className="TeamMemberImage"
                  style={{}}
                />
                <h4 className="TeamMemberName">Oleksandr Lichevetskyy</h4>
                <h5 className="TeamMemberStatus">
                  Big boss & Sales Representative
                </h5>
                <a href="tel:+16472877555">
                  <h6 className="TeamMemberNumber">+1 (647) 287 - 7555</h6>
                </a>
                <a href="mailto:oleksandr@myestatesgroup.com">
                  <h6 className="TeamMemberEmail">
                    oleksandr@myestatesgroup.com
                  </h6>
                </a>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} md={4} sm={6} style={{ marginBottom: "24px" }}>
            <div
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                margin: "0",
                width: "100%",
                marginTop: "24px",
              }}
              onClick={this.handleOpenAltagracia}
            >
              <div className="PersonDiv">
                <img
                  src={Lera}
                  alt={"Valeria Khomovych"}
                  className="TeamMemberImage"
                  style={{}}
                />
                <h4 className="TeamMemberName">Altagracia Dewindt</h4>
                <h5 className="TeamMemberStatus">In-House Sales Executive</h5>
                <a href="tel:+12023301367">
                  <h6 className="TeamMemberNumber">+1 (202) 330 - 1367</h6>
                </a>
                <a href="mailto:apdewindt@aol.com">
                  <h6 className="TeamMemberEmailDialog">apdewindt@aol.com</h6>
                </a>
              </div>
            </div>
          </Grid>
        </Grid>

        <h3 className="ContactUsBookMeetingText">
          Book a call meeting with our team
        </h3>

        {/* <InlineWidget url="https://calendly.com/batestaaa13?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=F1EDE9&text_color=29282d&primary_color=6868ac" styles={{ height: '650px'}}/> */}
        <InlineWidget
          url="https://calendly.com/myestatesgr/15min?hide_landing_page_details=1&hide_gdpr_banner=1&background_color=F1EDE9&text_color=29282d&primary_color=6868ac"
          styles={{ height: "700px" }}
        />

        <Footer windowWidth={this.props.windowWidth} />
      </div>
    );
  }
}

export default ContactUs;
