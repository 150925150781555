import React, { Component, useState } from "react";
// import axios from 'axios';
import { useSearchParams } from "react-router-dom";

import { Link } from "react-router-dom";

import propertyBG from "../images/propertiesBG.jpg";

import "./stylePages/properties.css";
import Footer from "../components/Footer";
import PropertyCard from "./extrasForPages/PropertyCard";
import MyButton from "../util/MyButton";

// MUI
import Button from "@mui/material/Button";
import Dialog from "@material-ui/core/Dialog";
import { Grid, withStyles } from "@material-ui/core";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import Slider from "@mui/material/Slider";
import TextField from "@mui/material/TextField";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import PropTypes from "prop-types";
import NumberFormat from "react-number-format";
// import FilterAltIcon from "@mui/icons-material/FilterAlt";
// import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import FilterAltTwoToneIcon from "@mui/icons-material/FilterAltTwoTone";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import KeyIcon from "@mui/icons-material/Key";

// import axios from "axios";
import { Helmet } from "react-helmet";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../store";

const NumberFormatCustom = React.forwardRef(function NumberFormatCustom(
  props,
  ref
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            // name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator
      isNumericString
      prefix="$"
    />
  );
});

NumberFormatCustom.propTypes = {
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};

function Properties(props) {
  const userCredentials = useCredentialsStore((state) => state.userCredentials);
  const authentication = useAuthenticatedStore((state) => state.authenticated);

  const CustomSwitch = withStyles({
    switchBase: {
      color: "green",
      "&$checked": {
        color: "red",
      },
      "&$checked + $track": {
        backgroundColor: "blue",
      },
    },
    checked: {},
    track: {},
  })(Switch);

  const CustomSlider = withStyles({
    root: {
      color: "#6868AC",
    },
    track: {
      // color: 'red'
    },
    thumb: {
      color: "blue",
    },
  })(Slider);

  const [searchParams, setSearchParams] = useSearchParams();

  // let propertiesArray = props.data.houses;
  let propertiesArray = props.data;

  // Sorting
  let sortByAvailabilityAsc = function (a, b) {
    if (a.status < b.status) {
      return -1;
    }
    if (a.status > b.status) {
      return 1;
    }
    return 0;
    // return a.status - b.status;
  };

  let sortByAvailabilityDesc = function (a, b) {
    if (a.status > b.status) {
      return -1;
    }
    if (a.status < b.status) {
      return 1;
    }
    return 0;
    //return b.status - a.status;
  };

  let sortByPriceAsc = function (a, b) {
    if (
      parseInt(a.minPrice.split(".")[0].replace(/\D/g, "")) <
      parseInt(b.minPrice.split(".")[0].replace(/\D/g, ""))
    ) {
      return 1;
    }
    if (
      parseInt(a.minPrice.split(".")[0].replace(/\D/g, "")) >
      parseInt(b.minPrice.split(".")[0].replace(/\D/g, ""))
    ) {
      return -1;
    }
    return 0;
    // return a.status - b.status;
  };

  let sortByPriceDesc = function (a, b) {
    if (
      parseInt(a.minPrice.split(".")[0].replace(/\D/g, "")) >
      parseInt(b.minPrice.split(".")[0].replace(/\D/g, ""))
    ) {
      return 1;
    }
    if (
      parseInt(a.minPrice.split(".")[0].replace(/\D/g, "")) <
      parseInt(b.minPrice.split(".")[0].replace(/\D/g, ""))
    ) {
      return -1;
    }
    return 0;
    //return b.status - a.status;
  };

  const formatter = new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    maximumFractionDigits: 0,
  });
  function valueLabelFormatPrice(value) {
    return formatter.format(value);
  }

  // const property = props.data[0];

  const [isOpen, setOpen] = useState(false);
  const [checked, setChecked] = useState(false);
  const [checkedAvailability, setCheckedAvailability] = useState(false);
  const [checkedHouses, setCheckedHouses] = useState(false);
  const [checkedApartments, setCheckedApartments] = useState(false);
  const [checkedAirbnb, setCheckedAirbnb] = useState(false);
  const [price, setPrice] = useState([400000, 50000000]);
  const [bedrooms, setBedrooms] = useState([1, 10]);
  const [bathrooms, setBathrooms] = useState([1, 10]);
  const [totalSize, setTotalSize] = useState([300, 8000]);
  const [sortType, setSortType] = useState(0);

  const handleChangeChecked = (event) => {
    setChecked(event.target.checked);
  };
  const handleChangeAvailability = (event) => {
    setCheckedAvailability(event.target.checked);
  };
  const handleChangeHouses = (event) => {
    setCheckedHouses(event.target.checked);
  };
  const handleChangeApartments = (event) => {
    setCheckedApartments(event.target.checked);
  };
  const handleChangeAirbnb = (event) => {
    setCheckedAirbnb(event.target.checked);
  };
  const handleChangePrice = (event, newValue) => {
    setPrice(newValue);
  };
  const handleChangeLowPrice = (event) => {
    setPrice([event.target.value, price[1]]);
  };
  const handleChangeTopPrice = (event) => {
    setPrice([price[0], event.target.value]);
  };
  const handleChangeBedrooms = (event, newValue) => {
    setBedrooms(newValue);
  };
  const handleChangeMinBedrooms = (event) => {
    setBedrooms([event.target.value, bedrooms[1]]);
  };
  const handleChangeMaxBedrooms = (event) => {
    setBedrooms([bedrooms[0], event.target.value]);
  };
  const handleChangeBathrooms = (event, newValue) => {
    setBathrooms(newValue);
  };
  const handleChangeMinBathrooms = (event) => {
    setBathrooms([event.target.value, bathrooms[1]]);
  };
  const handleChangeMaxBathrooms = (event) => {
    setBathrooms([bathrooms[0], event.target.value]);
  };
  const handleChangeTotalSize = (event, newValue) => {
    setTotalSize(newValue);
  };
  const handleChangeMinTotalSize = (event) => {
    setTotalSize([event.target.value, totalSize[1]]);
  };
  const handleChangeMaxTotalSize = (event) => {
    setTotalSize([totalSize[0], event.target.value]);
  };
  const handleChangeSortType = (event) => {
    setSortType(event.target.value);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = () => {
    console.log("Handle Submit Form triggered");
  };

  const toDefaultValues = () => {
    setSearchParams({});
    setCheckedAvailability(false);
    setCheckedHouses(false);
    setCheckedApartments(false);
    setCheckedAirbnb(false);
    setPrice([400000, 50000000]);
    setBedrooms([1, 10]);
    setBathrooms([1, 10]);
    setTotalSize([300, 8000]);
    setSortType(0);
  };

  //FILTER
  let filteredArray = propertiesArray;
  if (searchParams.get("availability") === "true") {
    filteredArray = filteredArray.filter(
      (item) => item.status.toLowerCase() === "available"
    );
  }
  if (
    searchParams.get("houses") === "true" &&
    searchParams.get("apartments") === "false"
  ) {
    filteredArray = filteredArray.filter(
      (item) => item.type.toLowerCase() === "house"
    );
  }
  if (
    searchParams.get("apartments") === "true" &&
    searchParams.get("houses") === "false"
  ) {
    filteredArray = filteredArray.filter(
      (item) => item.type.toLowerCase() === "apartment"
    );
  }

  if (searchParams.get("airbnb") === "true") {
    filteredArray = filteredArray.filter((item) => item.airbnb === true);
  }

  if (searchParams.get("price") !== null) {
    filteredArray = filteredArray.filter(
      (item) =>
        (parseInt(item.minPrice.split(".")[0].replace(/\D/g, "")) >=
          parseInt(searchParams.getAll("price")[0]) &&
          parseInt(item.minPrice.split(".")[0].replace(/\D/g, "")) <=
            parseInt(searchParams.getAll("price")[1])) ||
        (parseInt(item.maxPrice.split(".")[0].replace(/\D/g, "")) >=
          parseInt(searchParams.getAll("price")[0]) &&
          parseInt(item.maxPrice.split(".")[0].replace(/\D/g, "")) <=
            parseInt(searchParams.getAll("price")[1]))
    );
  }
  if (searchParams.get("bedrooms") !== null) {
    filteredArray = filteredArray.filter(
      (item) =>
        (parseInt(item.minBeds) >=
          parseInt(searchParams.getAll("bedrooms")[0]) &&
          parseInt(item.minBeds) <=
            parseInt(searchParams.getAll("bedrooms")[1])) ||
        (parseInt(item.maxBeds) >=
          parseInt(searchParams.getAll("bedrooms")[0]) &&
          parseInt(item.maxBeds) <=
            parseInt(searchParams.getAll("bedrooms")[1]))
    );
  }
  if (searchParams.get("bathrooms") !== null) {
    filteredArray = filteredArray.filter(
      (item) =>
        (parseInt(item.minBath) >=
          parseInt(searchParams.getAll("bathrooms")[0]) &&
          parseInt(item.minBath) <=
            parseInt(searchParams.getAll("bathrooms")[1])) ||
        (parseInt(item.maxBath) >=
          parseInt(searchParams.getAll("bathrooms")[0]) &&
          parseInt(item.maxBath) <=
            parseInt(searchParams.getAll("bathrooms")[1]))
    );
  }
  if (searchParams.get("size") !== null) {
    filteredArray = filteredArray.filter(
      (item) =>
        (parseInt(item.minSize) >= parseInt(searchParams.getAll("size")[0]) &&
          parseInt(item.minSize) <= parseInt(searchParams.getAll("size")[1])) ||
        (parseInt(item.maxSize) >= parseInt(searchParams.getAll("size")[0]) &&
          parseInt(item.maxSize) <= parseInt(searchParams.getAll("size")[1]))
    );
  }

  //SORTING
  let sortedArray = filteredArray;
  switch (searchParams.get("sortBy")) {
    case "0":
      //new first
      sortedArray = filteredArray; //Working
      break;
    case "1":
      //TODO add sorted by time old first
      //Old first
      // sortedArray = [...propertiesArray].reverse();
      let tmp = [...sortedArray].reverse();
      sortedArray = tmp;
      break;
    case "2":
      //Price: High to low
      sortedArray = [...filteredArray].sort(sortByPriceAsc); //Working Weird
      break;
    case "3":
      //Price: Low to high
      sortedArray = [...filteredArray].sort(sortByPriceDesc); //Working weird
      break;
    case "4":
      //Alailable first
      sortedArray = [...filteredArray].sort(sortByAvailabilityAsc); //Working
      break;
    case "5":
      //Sold first
      sortedArray = [...filteredArray].sort(sortByAvailabilityDesc); //Working
      break;
    default:
      //TODO add sort by time new first
      sortedArray = filteredArray;
      break;
  }

  // propertiesArray = propertiesArray.filter( (item) => item.type.includes("Apartment") )
  // let reversed = [...propertiesArray].reverse();
  // let sortedArray = [...propertiesArray].sort(sortByAvailabilityAsc);

  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>My Estates Group - All projects</title>
      </Helmet>
      <div
        style={{ marginTop: "-80px", backgroundImage: `url(${propertyBG})` }}
        className="propertiesBGImage"
      >
        <div className="propertiesOverlay">
          <h3 className="propertyMainCompanyName">MY ESTATES GROUP</h3>
          <h4 className="propertyMainSecondaryLine">ALL PROJECTS</h4>
          <Button onClick={handleClickOpen} className="propertiesButtonFilter">
            <FilterAltTwoToneIcon fontSize="medium" />
          </Button>
          {authentication && userCredentials.isAdmin ? (
            <div>
              <div>
                <Button
                  component={Link}
                  to="/addproperty"
                  style={{
                    // textTransform: "none",
                    color: "#F1EDE9",
                  }}
                >
                  {/* Add property */}

                  <MyButton tip="Add property">
                    <AddCircleOutlineIcon
                      fontSize="large"
                      style={{ color: "#F1EDE9" }}
                    />
                  </MyButton>
                </Button>
              </div>
              <div>
                <Button
                  component={Link}
                  to="/secretpage"
                  style={{
                    // textTransform: "none",
                    color: "#F1EDE9",
                  }}
                >
                  {/* Add property */}

                  <MyButton tip="Secret page">
                    <KeyIcon fontSize="large" style={{ color: "#F1EDE9" }} />
                  </MyButton>
                </Button>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div className="propertyCardContainer">
        {/* {data ? ('loaded') : ('loading')} */}
        {sortedArray ? (
          // <p>Recieved data</p>
          sortedArray.map(function (property, i) {
            return <PropertyCard key={i} data={property} />;
          })
        ) : (
          <p>loading</p>
        )}
        {/* {sortedArray.map(function(property, i){
          return <PropertyCard key={i} data={property}/>
      })} */}

        <Dialog onClose={handleClose} open={isOpen} style={{}}>
          <form noValidate onSubmit={handleSubmit}>
            <h3 className="filterTopHeader">Filter</h3>
            <FormGroup>
              <div style={{ width: "80%", margin: "auto", marginTop: "16px" }}>
                <div>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={checkedAvailability}
                        onChange={handleChangeAvailability}
                        size="large"
                        style={{ color: "blue" }}
                      />
                    }
                    label="Show only available"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={checkedHouses}
                        onChange={handleChangeHouses}
                        size="large"
                        style={{ color: "blue" }}
                      />
                    }
                    label="Show houses"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={checkedApartments}
                        onChange={handleChangeApartments}
                        size="large"
                        style={{ color: "blue" }}
                      />
                    }
                    label="Show condos"
                  />
                </div>
                <div>
                  <FormControlLabel
                    control={
                      <CustomSwitch
                        checked={checkedAirbnb}
                        onChange={handleChangeAirbnb}
                        size="large"
                        style={{ color: "blue" }}
                      />
                    }
                    label="Airbnb friendly"
                  />
                </div>
              </div>

              <h4
                className="filterTopHeader"
                style={{
                  marginTop: "40px",
                  fontSize: "20px",
                  marginBottom: "0px",
                }}
              >
                Price
              </h4>
              <CustomSlider
                getAriaLabel={() => "Price"}
                value={price}
                step={50000}
                min={300000}
                max={50000000}
                onChange={handleChangePrice}
                valueLabelDisplay="auto"
                valueLabelFormat={valueLabelFormatPrice}
                style={{ width: "80%", margin: "auto", marginTop: "16px" }}
                disableSwap
              />
              <Grid container spacing={0} style={{ marginTop: "8px" }}>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={lowPrice}
                    value={price[0]}
                    style={{ width: "80%", margin: "auto" }}
                    helperText="Lowest price"
                    onChange={handleChangeLowPrice}
                    type="tel"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={topPrice}
                    value={price[1]}
                    style={{ width: "80%", alignSelf: "center" }}
                    helperText="Highest price"
                    onChange={handleChangeTopPrice}
                    type="tel"
                    InputProps={{
                      inputComponent: NumberFormatCustom,
                    }}
                  />
                </Grid>
              </Grid>

              <h4
                className="filterTopHeader"
                style={{
                  marginTop: "32px",
                  fontSize: "20px",
                  marginBottom: "0px",
                }}
              >
                Bedrooms
              </h4>
              <CustomSlider
                getAriaLabel={() => "Bedrooms"}
                value={bedrooms}
                step={1}
                min={1}
                max={10}
                onChange={handleChangeBedrooms}
                valueLabelDisplay="auto"
                // valueLabelFormat={valueLabelFormatPrice}
                style={{ width: "80%", margin: "auto", marginTop: "16px" }}
                disableSwap
              />
              <Grid container spacing={0} style={{ marginTop: "8px" }}>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={lowPrice}
                    value={bedrooms[0]}
                    style={{ width: "80%", margin: "auto" }}
                    helperText="Minimum bedrooms"
                    onChange={handleChangeMinBedrooms}
                    type="tel"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={topPrice}
                    value={bedrooms[1]}
                    style={{ width: "80%", alignSelf: "center" }}
                    helperText="Maximum bedrooms"
                    onChange={handleChangeMaxBedrooms}
                    type="tel"
                  />
                </Grid>
              </Grid>

              <h4
                className="filterTopHeader"
                style={{
                  marginTop: "32px",
                  fontSize: "20px",
                  marginBottom: "0px",
                }}
              >
                Bathrooms
              </h4>
              <CustomSlider
                getAriaLabel={() => "Bathrooms"}
                value={bathrooms}
                step={1}
                min={1}
                max={10}
                onChange={handleChangeBathrooms}
                valueLabelDisplay="auto"
                // valueLabelFormat={valueLabelFormatPrice}
                style={{ width: "80%", margin: "auto", marginTop: "16px" }}
                disableSwap
              />
              <Grid container spacing={0} style={{ marginTop: "8px" }}>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={lowPrice}
                    value={bathrooms[0]}
                    style={{ width: "80%", margin: "auto" }}
                    helperText="Minimum Bathrooms"
                    onChange={handleChangeMinBathrooms}
                    type="tel"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={topPrice}
                    value={bathrooms[1]}
                    style={{ width: "80%", alignSelf: "center" }}
                    helperText="Maximum bathrooms"
                    onChange={handleChangeMaxBathrooms}
                    type="tel"
                  />
                </Grid>
              </Grid>

              <h4
                className="filterTopHeader"
                style={{
                  marginTop: "32px",
                  fontSize: "20px",
                  marginBottom: "0px",
                }}
              >
                Total size (SqFt)
              </h4>
              <CustomSlider
                getAriaLabel={() => "Total Size"}
                value={totalSize}
                step={50}
                min={300}
                max={8000}
                onChange={handleChangeTotalSize}
                valueLabelDisplay="auto"
                // valueLabelFormat={valueLabelFormatPrice}
                style={{ width: "80%", margin: "auto", marginTop: "16px" }}
                disableSwap
              />
              <Grid container spacing={0} style={{ marginTop: "8px" }}>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={lowPrice}
                    value={totalSize[0]}
                    style={{ width: "80%", margin: "auto" }}
                    helperText="Minimum Total Size"
                    onChange={handleChangeMinTotalSize}
                    type="tel"
                  />
                </Grid>
                <Grid
                  item
                  sm={6}
                  xs={12}
                  style={{ display: "flex", justifyContent: "center" }}
                >
                  <TextField
                    // value={topPrice}
                    value={totalSize[1]}
                    style={{ width: "80%", alignSelf: "center" }}
                    helperText="Maximum Total Size"
                    onChange={handleChangeMaxTotalSize}
                    type="tel"
                  />
                </Grid>
              </Grid>

              <h3 className="filterTopHeader" style={{ marginTop: "40px" }}>
                Sort
              </h3>
              <Select
                label="Sort by"
                value={sortType}
                onChange={handleChangeSortType}
                style={{
                  marginTop: "16px",
                  width: "80%",
                  alignSelf: "center",
                  marginBottom: "32px",
                }}
              >
                <MenuItem value={0}>New first</MenuItem>
                <MenuItem value={1}>Old first</MenuItem>
                <MenuItem value={2}>Price: High to low</MenuItem>
                <MenuItem value={3}>Price: Low to high</MenuItem>
                <MenuItem value={4}>Available first</MenuItem>
                <MenuItem value={5}>Sold first</MenuItem>
              </Select>
            </FormGroup>

            <Grid container spacing={0} style={{ marginBottom: "40px" }}>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  onClick={handleClose}
                  className="propertyFilterButton"
                  style={{ color: "brown", fontSize: "16px" }}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid
                item
                xs={6}
                style={{ display: "flex", justifyContent: "center" }}
              >
                <Button
                  className="propertyFilterButton"
                  style={{ color: "green", fontSize: "16px" }}
                  onClick={() =>
                    setSearchParams({
                      availability: checkedAvailability,
                      houses: checkedHouses,
                      apartments: checkedApartments,
                      airbnb: checkedAirbnb,
                      price: price,
                      bedrooms: bedrooms,
                      bathrooms: bathrooms,
                      size: totalSize,
                      sortBy: sortType,
                    })
                  }
                >
                  Save
                </Button>
              </Grid>
            </Grid>
            <a className="propertyResetBtn" onClick={() => toDefaultValues()}>
              Reset
            </a>
          </form>
        </Dialog>
      </div>
      <div style={{ height: "100vh" }} />
      <Footer />
    </div>
  );
}

export default Properties;

{
  /* 
      
  Things to filter:
    -isAvailable? true : false;
    -priceRange? from : to;
    -apartments? true : false;
    -houses? true : false;
    -parking? true : false; | Not sure
    -Number of bedrooms? from : to;
    -Number of bathrooms? from : to;
    -Total size? from : to;


*/
}
