import React, { Component } from "react";
import { Link } from "react-router-dom";

import "../styles/footer.css";

// Images
import logo from "../images/MyEstatesGroupLogo.png";
import { Button } from "@mui/material";

// MUI
import { Grid } from "@material-ui/core";

export default class Footer extends Component {
  render() {
    return (
      <div className="footerMain">
        <Grid container spacing={0} style={{ paddingTop: "40px" }}>
          <Grid item xs={12} md={4}>
            <img src={logo} alt={"Logo"} className="footerLogo" />
          </Grid>
          <Grid item xs={false} md={1}></Grid>

          <Grid item xs={6} md={3}>
            <Button
              component={Link}
              to="/map"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Properties map
            </Button>
            <Button
              component={Link}
              to="/properties"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              List of properties
            </Button>
            {/* <Button component={Link} to="/contactus" className='footerList' style={{textTransform: "none", color: '#F1EDE9', fontFamily: 'Jost', fontWeight: '300', fontSize: '18px', display: 'block', margin: 'auto', textAlign: 'left'}}>Book a zoom meeting</Button> */}
            <Button
              component={Link}
              to="/contactus"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Contact us
            </Button>
          </Grid>

          <Grid item xs={6} md={4}>
            <Button
              component={Link}
              to="/login"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Login
            </Button>
            <Button
              component={Link}
              to="/signup"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Sign up
            </Button>
            <Button
              component={Link}
              to="/"
              className="footerList"
              style={{
                textTransform: "none",
                color: "#F1EDE9",
                fontFamily: "Jost",
                fontWeight: "300",
                fontSize: "18px",
                display: "block",
                margin: "auto",
                textAlign: "left",
              }}
            >
              Home
            </Button>
          </Grid>
        </Grid>

        <p className="footerRights">
          All rights reserved and owned by My Estates Group Inc. @ 2022
        </p>
        <Button
          component={Link}
          to="/terms"
          className="footerMade"
          style={{
            textTransform: "none",
            textDecoration: "underline",
            color: "#F1EDE9",
            fontFamily: "Jost",
            fontWeight: "300",
            fontSize: "18px",
            display: "block",
            margin: "auto",
            textAlign: "center",
          }}
        >
          By using this website you agree with our Terms and Conditions
        </Button>
        <a
          className="footerMade"
          href="https://vilkun.software/"
          target="_blank"
        >
          Made by Vellkunio
        </a>
        <div className="footerSpacing" />
      </div>
    );
  }
}
