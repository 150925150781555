import React, { useState } from "react";

import { Link } from "react-router-dom";

//MUI
import Slider from "@mui/material/Slider";

//Zustand
import { useAuthenticatedStore, useCredentialsStore } from "../../store";

const marks = [
  {
    value: 1,
    label: "1 year",
  },
  {
    value: 10,
    label: "10 years",
  },
];

const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export default function ProfitCalculator(props) {
  const authentication = useAuthenticatedStore((state) => state.authenticated);
  const [value, setValue] = useState("5");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      {/* <div className="ProfitCalcMainDiv"> */}
      <div className="ProfitCalcMainDiv">
        {/* <div className="BlurForNonLoggedInOnProfit" /> */}
        <h6 className="ProfitCalcHeader">Potential income in {value} years</h6>
        <Slider
          aria-label="Investment return"
          defaultValue={5}
          value={value}
          onChange={handleChange}
          valueLabelDisplay="auto"
          step={1}
          marks={marks}
          min={1}
          max={11}
          sx={{ width: "80%" }}
          style={{ display: "block", marginLeft: "auto", marginRight: "auto" }}
        />
        {authentication === true
          ? formatter.format(
              parseInt(
                props.price.split(".")[0].replace(/\D/g, "") *
                  Math.pow(1 + parseFloat(props.index), value)
              )
            )
          : formatter
              .format(
                props.price.split(".")[0].replace(/\D/g, "") *
                  Math.pow(1 + parseFloat(props.index), value)
              )
              .replace(/\d/g, "*")}
        {authentication === true ? (
          <div></div>
        ) : (
          <p className="loginToSeeProfit">
            Please,{" "}
            <Link to="/login" style={{ color: "#2cb67d" }}>
              Login
            </Link>{" "}
            to see potential profit
          </p>
        )}

        {/* {formatter.format(
          parseInt(
            props.price.split(".")[0].replace(/\D/g, "") *
              Math.pow(1 + parseFloat(props.index), value)
          )
        )} */}
      </div>
    </div>
  );
}
