import React from 'react'

export default function MiniMap(props) {
  return (
    <div>

        <a href={props.link} target="_blank">
            <img src={props.image} alt="Google Map" className='googleMapStyle'/>
        </a>

    </div>
  )
}
