import create from "zustand";
import { devtools, persist } from "zustand/middleware";

let credentialsStore = (set) => ({
  userCredentials: {},
  setUserCredentials: (credentials) =>
    set((state) => ({ userCredentials: { ...credentials } })),
});

let authenticatedStore = (set) => ({
  authenticated: false,
  setAuthenticated: (status) => set((state) => ({ authenticated: status })),
  token: "",
  setToken: (newToken) => set((state) => ({ token: newToken })),
});

credentialsStore = persist(credentialsStore, { name: "user_credentials" });
credentialsStore = devtools(credentialsStore);

authenticatedStore = devtools(authenticatedStore);
// authenticatedStore = persist(authenticatedStore, { name: "Authentication" });

export const useCredentialsStore = create(credentialsStore);
export const useAuthenticatedStore = create(authenticatedStore);

// import create from 'zustand'
// import { devtools, persist } from 'zustand/middleware'

// let credentialsStore = (set) => ({
//     userCredentials: {email:'123@gmail.com', fullName:'Serhii Vilkun'},
//     setUserCredentials: (email, fullName) =>
//         set((state) => ({ userCredentials: {email: email, fullName: fullName} })),
// })

// let numberStore = (set) => ({
//     number: 3,
//     setNumber: (num) => set((state) => ({number: num}))
// })

// credentialsStore = devtools(credentialsStore)
// credentialsStore = persist(credentialsStore, { name: 'user_settings'})

// numberStore = devtools(numberStore)

// export const useCredentialsStore = create(credentialsStore)
// export const useNumberStore = create(numberStore)
