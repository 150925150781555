import React, { useState } from "react";

// MUI
import { Grid } from "@material-ui/core";
import { Button } from "@material-ui/core";
import Dialog from "@material-ui/core/Dialog";

//Swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles3.css"; TO DELETE FILE

function ImageSlider(props) {
  const [isOpen, setOpen] = useState(false);

  const imageSwiper = (
    <div>
      <Swiper
        spaceBetween={30}
        centeredSlides={true}
        autoplay={{
          delay: 5000,
          disableOnInteraction: true,
        }}
        pagination={{
          clickable: true,
        }}
        navigation={true}
        modules={[Autoplay, Pagination, Navigation]}
        // style={{maxHeight: '350px'}}
      >
        {props.images.map(function (item, i) {
          return (
            <SwiperSlide key={i}>
              <img src={item} alt="image" />
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );

  return (
    <div>
      <Button
        style={{
          background: "transparent",
          position: "relative",
          display: "block",
        }}
        onClick={() => setOpen(true)}
      >
        <Grid container spacing={0} style={{ dispaly: "block" }}>
          <Grid item sm={1} xs={0}></Grid>
          <Grid item sm={5} xs={12}>
            <img
              src={props.images[0]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
          </Grid>
          <Grid item sm={5} xs={12}>
            <img
              src={props.images[1]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
          </Grid>
          <Grid item sm={1} xs={0}></Grid>
        </Grid>
        <Grid container spacing={0}>
          <Grid item sm={1}></Grid>
          <Grid item xs={6} sm={props.images.length - 6 > 0 ? 3 : 5}>
            <img
              src={props.images[2]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
            <img
              src={props.images[3]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
          </Grid>
          <Grid item xs={6} sm={props.images.length - 6 > 0 ? 3 : 5}>
            <img
              src={props.images[4]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
            <img
              src={props.images[5]}
              alt="House pic 1"
              className="ImageSliderTopPic"
            />
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            style={{
              display: props.images.length - 6 > 0 ? "inline-block" : "none",
            }}
          >
            <p className="ImageSliderNumberRemain">
              +{props.images.length - 6}
            </p>
          </Grid>
          <Grid item sm={1} xs={0}></Grid>
        </Grid>
      </Button>

      <Dialog
        className="ImageSliderDialogImg"
        open={isOpen}
        onClose={() => setOpen(false)}
        PaperProps={{
          style: {
            background: "rgba(100, 100, 100, 0.5)",
            width: "auto",
            height: "auto",
            backdropFilter: "blur(5px)",
            WebkitBackdropFilter: "blur(5px)",
            //   boxShadow: 'none',
          },
        }}
        style={{
          background: "rgba(0,0,0,.6)",
        }}
      >
        {imageSwiper}
      </Dialog>
    </div>
  );
}

export default ImageSlider;
