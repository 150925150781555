import React from 'react'
import Feature from './Feature'

function FeaturesList({features, removeFeature}) {
  return (
    <div>
        {features.map((feature, index) => {
            return (
                <Feature key={index} feature={feature} removeFeature={removeFeature} index={index}/>
            )
        })}
    </div>
  )
}

export default FeaturesList;