import React, { Component } from "react";

//Images
import miami2 from "../images/miami2.jpg";

//Components
import ReasonToBuy from "./elements/ReasonToBuy";

import AOS from "aos";

class WhyToBuy extends Component {
  render() {
    let reason = (
      <div>
        <ReasonToBuy
          data-aos="fade-up"
          data-aos-anchor-placement="top-bottom"
          text="No bidding wars"
          marginTop="140px"
          marginLeft="70px"
        />
        <ReasonToBuy
          text="Save your $ from inflation"
          marginTop="220px"
          marginLeft="-300px"
        />
        <ReasonToBuy
          text="Buy assets to afford liabilities"
          marginTop="300px"
          marginLeft="280px"
        />
        <ReasonToBuy
          text="Passive income"
          marginTop="380px"
          marginLeft="-170px"
        />
        <ReasonToBuy
          text="10 days cooling period (Recession)"
          marginTop="480px"
          marginLeft="150px"
        />
      </div>
    );

    if (this.props.windowWidth > 910) {
      reason = (
        <div>
          <ReasonToBuy
            data-aos="fade-up"
            data-aos-anchor-placement="top-bottom"
            text="No bidding wars"
            marginTop="200px"
            marginLeft="70px"
          />
          <ReasonToBuy
            text="Save your $ from inflation"
            marginTop="280px"
            marginLeft="-300px"
          />
          <ReasonToBuy
            text="Buy assets to afford liabilities"
            marginTop="360px"
            marginLeft="280px"
          />
          <ReasonToBuy
            text="Passive income"
            marginTop="440px"
            marginLeft="-170px"
          />
          <ReasonToBuy
            text="10 days cooling period (Recession)"
            marginTop="540px"
            marginLeft="150px"
          />
        </div>
      );
    } else if (this.props.windowWidth <= 910 && this.props.windowWidth >= 600) {
      reason = (
        <div>
          <ReasonToBuy
            text="No bidding wars"
            marginTop="150px"
            marginLeft="-12%"
          />
          <ReasonToBuy
            text="Save your $ from inflation"
            marginTop="266px"
            marginLeft="12%"
          />
          <ReasonToBuy
            text="Buy assets to afford liabilities"
            marginTop="382px"
            marginLeft="-12%"
          />
          <ReasonToBuy
            text="Passive income"
            marginTop="498px"
            marginLeft="12%"
          />
          <ReasonToBuy
            text="10 days cooling period (Recession)"
            marginTop="614px"
            marginLeft="-12%"
          />
        </div>
      );
    } else if (this.props.windowWidth < 600) {
      reason = (
        <div>
          <ReasonToBuy
            text="No bidding wars"
            marginTop="100px"
            marginLeft="-5%"
          />
          <ReasonToBuy
            text="Save your $ from inflation"
            marginTop="216px"
            marginLeft="5%"
          />
          <ReasonToBuy
            text="Buy assets to afford liabilities"
            marginTop="332px"
            marginLeft="-5%"
          />
          <ReasonToBuy
            text="Passive income"
            marginTop="448px"
            marginLeft="5%"
          />
          <ReasonToBuy
            text="10 days cooling period (Recession)"
            marginTop="564px"
            marginLeft="-5%"
          />
        </div>
      );
    }

    return (
      <div style={{ marginTop: "30px" }}>
        <h2
          className="headerWhybuy"
          data-aos="fade-top"
          data-aos-anchor-placement="center-bottom"
        >
          WHY PRE-CONSTRUCTION?
        </h2>

        {reason}

        <div
          className="WhyBuyImg"
          style={{
            backgroundImage: `url(${miami2})`,
          }}
        ></div>
      </div>
    );
  }
}

export default WhyToBuy;
